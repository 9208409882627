import { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import ShoppingList from "./ShoppingList";

const useStyles = makeStyles((theme) => ({
  // paper: {
  //   marginTop: theme.spacing(4),
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  // },
  // form: {
  //   width: "100%", // Fix IE 11 issue.
  //   marginTop: theme.spacing(1),
  // },
  submit: {
    borderRadius: "0 0 4px 4px",
  },
  spinProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
  },
  alertMessage: {
    marginTop: "8px",
  },
}));

// This flag errorForm is used to avoid the "race" condition by using the values set using useState()
let errorForm = false;

function ShoppingCheckout(props) {
  const classes = useStyles();

  const [promo, setPromo] = useState("");
  const [promoValidation, setPromoValidation] = useState({
    error: false,
    errorMsg: "",
  });

  const [loading, setLoading] = useState(true);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [serviceItems, setServiceItems] = useState([]);
  const [renderFlag, setRenderFlag] = useState(0);
  const [noCharge, setNoCharge] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    props.onCall("paymentFlowC", props.cartItems).then((result) => {
      console.log("result from payment intent ", result.newIntent);
      setClientSecret(result.newIntent);
      // Initialize shopping list
      setServiceItems(result.newItems);
      setLoading(false);
      //Clear the cart from the main app
      props.onUpdate([]);
    });
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  function totalPrice(items) {
    return items
      .map(({ packagePrice }) => packagePrice)
      .reduce((sum, i) => sum + i, 0);
  }

  function handleChangePromo(event) {
    const { name, value } = event.target;
    setPromoValidation({
      error: false,
      errorMsg: "",
    });
    setPromo(value);
  }

  function checkValidation() {
    if (promo.length < 1) {
      setPromoValidation((prevValue) => {
        return {
          ...prevValue,
          error: true,
          errorMsg: "This code value can't be blank",
        };
      });
      errorForm = true;
    } else {
      errorForm = false;
    }

    // Removing send email error message if client trying to send message again
    // if (!errorForm) {
    //   setErrMessage({
    //     error: false,
    //     errorMsg: "",
    //   });
    // }
  }

  function applyPromo() {
    checkValidation();
    console.log("error on form elements = " + errorForm);
    if (!errorForm) {
      //Changing renderFlag to enable a rendering update
      setRenderFlag(renderFlag + 1);
      //Update the intent with the new cart list
      setLoading(true);
      let payloadPromo = { newItems: serviceItems, promoCode: promo };
      if (totalPrice(serviceItems) != 0) {
        props.onCall("findPromoCode", payloadPromo).then((result) => {
          // console.log("result from update of intent ", result);
          // Re-Initialize shopping list
          if (result.errFlag) {
            setPromoValidation((prevValue) => {
              return {
                ...prevValue,
                error: true,
                errorMsg: result.errMsg,
              };
            });
          } else {
            setServiceItems(result.newItems);
            setClientSecret(result.newIntent);
          }
        });
      }
      // Initialize shopping list
      //TODO:  Need additional logic that if items are not empty, then enable a "complete transaction button"
      setLoading(false);
    }
    if (totalPrice(serviceItems) <= 0 && serviceItems.length >= 2) {
      setNoCharge(true);
      console.log("no charge is true");
    } else {
      setNoCharge(false);
    }
  }

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      elements.getElement(CardElement).clear();
      let payloadSubmit = { newItems: serviceItems, email: props.email };
      props.onCall("addPackage", payloadSubmit).then((result) => {
        console.log("result from adding package ", result);
        if (result !== "1111") {
          setError(
            "Payment succeeded.  Yet there was a problem updating your account.  Please contact us to solve this is issue."
          );
        }
      });
    }
  };

  const handleSubmitNoCharge = async (ev) => {
    ev.preventDefault();

    setError(null);
    setProcessing(false);
    setSucceeded(true);
    let payloadSubmit = { newItems: serviceItems, email: props.email };
    props.onCall("addPackage", payloadSubmit).then((result) => {
      console.log("result from adding package ", result);
      if (result !== "1111") {
        setError(
          "There was a problem updating your account.  Please contact us to solve this is issue."
        );
      }
    });
  };

  function handleDelete(index) {
    //setServiceItems((prevItems) => {
    //  prevItems.splice(index, 1);
    //  return prevItems;
    //});
    let itemsEdited = serviceItems;
    itemsEdited.splice(index, 1);
    console.log("index=", index);
    console.log("edited array = ", itemsEdited);
    //Changing renderFlag to enable a rendering update
    setRenderFlag(renderFlag + 1);
    //Update the intent with the new cart list
    setLoading(true);
    let payload = { newItems: itemsEdited, oldKey: clientSecret };
    if (totalPrice(itemsEdited) != 0) {
      props.onCall("updatePaymentC", payload).then((result) => {
        console.log("result from update of intent ", result);
        setClientSecret(result.newIntent);
        setServiceItems(result.newItems);
      });
    }
    // Initialize shopping list
    //TODO:  Need additional logic that if items are not empty, then enable a "complete transaction button"
    setLoading(false);
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={8}>
          <TextField
            // error={nameValidation.error}
            // helperText={nameValidation.errorMsg}
            className={classes.nameField}
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            name="promo"
            label="Promo Code or Gift Card"
            placeholder="Promo Code, Gift Card"
            type="text"
            id="promo"
            autoComplete="promo"
            value={promo}
            onChange={handleChangePromo}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            autoFocus
            fullWidth
            disabled={false}
            variant="contained"
            // style={{ backgroundColor: "#577c5c", color: "white" }}
            color="primary"
            onClick={(event) => {
              console.log("button pressed");
              applyPromo();
              event.preventDefault();
            }}
          >
            Apply
          </Button>
        </Grid>
        {promoValidation.error && (
          <Alert severity="error" className={classes.alertMessage}>
            {promoValidation.errorMsg}
          </Alert>
        )}
        <Grid item xs={8}>
          <TextField
            // error={nameValidation.error}
            // helperText={nameValidation.errorMsg}
            className={classes.nameField}
            fullWidth
            disabled={true}
            variant="outlined"
            margin="normal"
            size="small"
            name="referral"
            type="text"
            id="referral"
            label="Referral Code"
            autoComplete="referral"
            value="No current referrals"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            autoFocus
            fullWidth
            disabled={false}
            type="submit"
            variant="contained"
            // style={{ backgroundColor: "#577c5c", color: "white" }}
            color="primary"
            onClick={(event) => {
              event.preventDefault();
            }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            m: 0,
            width: "100%",
            position: "relative",
          }}
        >
          <ShoppingList
            cartItems={serviceItems}
            onDelete={handleDelete}
            disableDelete={succeeded}
          />
          {loading && (
            <CircularProgress size={24} className={classes.spinProgress} />
          )}
        </Box>
      </Box>

      {!noCharge && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardElement
            id="card-element"
            options={cardStyle}
            options={{ disabled: succeeded }}
            onChange={handleChange}
            blur={succeeded}
          />
          <Box
            sx={{
              m: 0,
              width: "100%",
              position: "relative",
            }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              // style={{ backgroundColor: "#577c5c", color: "white" }}
              color="primary"
              disabled={processing || disabled || succeeded}
              className={classes.submit}
              onClick={handleSubmit}
            >
              Pay Now
            </Button>
            {processing && (
              <CircularProgress size={24} className={classes.spinProgress} />
            )}
          </Box>
        </Box>
      )}
      {noCharge && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              m: 0,
              width: "100%",
              position: "relative",
            }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              // style={{ backgroundColor: "#577c5c", color: "white" }}
              color="primary"
              disabled={processing || succeeded}
              className={classes.submit}
              onClick={handleSubmitNoCharge}
            >
              Apply Now
            </Button>
            {processing && (
              <CircularProgress size={24} className={classes.spinProgress} />
            )}
          </Box>
        </Box>
      )}
      {/* <button disabled={processing || disabled || succeeded} id="submit">
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay now"
          )}
        </span>
      </button> */}
      {/* Show any error that happens when processing the payment */}
      {/* {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )} */}
      {/* Show a success message upon completion */}
      {/* <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded, see the result in your
        <a href={`https://dashboard.stripe.com/test/payments`}>
          {" "}
          Stripe dashboard.
        </a>{" "}
        Refresh the page to pay again.
      </p> */}
      {error && (
        <Alert severity="error" className={classes.alertMessage}>
          {error}
        </Alert>
      )}
      {succeeded && (
        <Alert severity="success" className={classes.alertMessage}>
          Payment succeeded. Thank you for your payment!
        </Alert>
      )}
    </form>
  );
}

export default ShoppingCheckout;
