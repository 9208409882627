const serviceListMassage = {
  mainTitle: "Massage",
  serviceView: [],
  list: [
    {
      key: 1,
      img: "images/Thai_massage.jpg",
      title: "Thai Massage",
      content:
        "Thai Massage is great for increasing flexibility, stimulating circulation, and having an overall feeling of relaxation and alertness.  It includes stretching to open the body and pressure points to release blockages.",
      more: "Thai Massage is done on a floor mat and the client wears loose, comfortable clothing.  It is good for any body type and athletic level.",
      pricing: "Select a single session or a package.",
      appt: "true",
      benefits: "Openness",
      avatarLetter: "M",
      pkgs: [
        {
          description: "1 x 60-minute = $100",
          value: {
            packageDesc: "1 x 60min Thai Massage",
            packagePrice: 100,
            sessions: 1,
            type: "service",
            used: false,
          },
        },
        {
          description: "1 x 90-minute = $145",
          value: {
            packageDesc: "1 x 90min Thai Massage",
            packagePrice: 145,
            sessions: 1,
            type: "service",
            used: false,
          },
        },
        {
          description: "1 x 120-minute = $190",
          value: {
            packageDesc: "1 x 120min Thai Massage",
            packagePrice: 190,
            sessions: 1,
            type: "service",
            used: false,
          },
        },
        {
          description: "3 x 60-minute = $285",
          value: {
            packageDesc: "3 x 60min Thai Massages",
            packagePrice: 285,
            sessions: 3,
            type: "service",
            used: false,
          },
        },
        {
          description: "3 x 90-minute = $420",
          value: {
            packageDesc: "3 x 90min Thai Massages",
            packagePrice: 420,
            sessions: 3,
            type: "service",
            used: false,
          },
        },
        {
          description: "3 x 120-minute = $540",
          value: {
            packageDesc: "3 x 120min Thai Massages",
            packagePrice: 540,
            sessions: 3,
            type: "service",
            used: false,
          },
        },
        {
          description: "10 x 60-minute = $900",
          value: {
            packageDesc: "10 x 60min Thai Massages",
            packagePrice: 900,
            sessions: 10,
            type: "service",
            used: false,
          },
        },
        {
          description: "10 x 90-minute = $1300",
          value: {
            packageDesc: "10 x 90min Thai Massages",
            packagePrice: 1300,
            sessions: 10,
            type: "service",
            used: false,
          },
        },
        {
          description: "10 x 120-minute = $1700",
          value: {
            packageDesc: "10 x 120min Thai Massages",
            packagePrice: 1700,
            sessions: 10,
            type: "service",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
    },
    {
      key: 2,
      img: "images/Deep_Tissue.jpg",
      title: "Deep Tissue Massage",
      content:
        "Deep Tissue Massage addresses the muscular knots in the body by combining Swedish massage techniques like effleurage with myofascial release techniques.  The intensity of the pressure can be adjusted as needed.",
      more: "Deep Tissue Massage is done on a masssage table.  Oil is applied on the body.  Optionally, aromatheray and hot stones can be included. ",
      pricing: "60 min - $100, 90 min - $140, 120 min - $170",
      pkgs: [
        {
          description: "1 x 60-minute = $100",
          value: {
            packageDesc: "1 x 60min Deep Tissue Massage",
            packagePrice: 100,
            sessions: 1,
            type: "service",
            used: false,
          },
        },
        {
          description: "1 x 90-minute = $145",
          value: {
            packageDesc: "1 x 90min Deep Tissue Massage",
            packagePrice: 145,
            sessions: 1,
            type: "service",
            used: false,
          },
        },
        {
          description: "1 x 120-minute = $190",
          value: {
            packageDesc: "1 x 120min Deep Tissue Massage",
            packagePrice: 190,
            sessions: 1,
            type: "service",
            used: false,
          },
        },
        {
          description: "3 x 60-minute = $285",
          value: {
            packageDesc: "3 x 60min Deep Tissue Massages",
            packagePrice: 285,
            sessions: 3,
            type: "service",
            used: false,
          },
        },
        {
          description: "3 x 90-minute = $420",
          value: {
            packageDesc: "3 x 90min Deep Tissue Massages",
            packagePrice: 420,
            sessions: 3,
            type: "service",
            used: false,
          },
        },
        {
          description: "3 x 120-minute = $540",
          value: {
            packageDesc: "3 x 120min Deep Tissue Massages",
            packagePrice: 540,
            sessions: 3,
            type: "service",
            used: false,
          },
        },
        {
          description: "10 x 60-minute = $900",
          value: {
            packageDesc: "10 x 60min Deep Tissue Massages",
            packagePrice: 900,
            sessions: 10,
            type: "service",
            used: false,
          },
        },
        {
          description: "10 x 90-minute = $1300",
          value: {
            packageDesc: "10 x 90min Deep Tissue Massages",
            packagePrice: 1300,
            sessions: 10,
            type: "service",
            used: false,
          },
        },
        {
          description: "10 x 120-minute = $1700",
          value: {
            packageDesc: "10 x 120min Deep Tissue Massages",
            packagePrice: 1700,
            sessions: 10,
            type: "service",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
      appt: "true",
      benefits: "Renewal",
      avatarLetter: "M",
    },
    {
      key: 3,
      img: "images/Foot_massage.jpg",
      title: "Foot Massage",
      content:
        "Foot Massage is based on Chinese Reflexology.  The main part of the session is focused on the reflexology points of the feet that correspond to organs and systems of the body, bringing relaxation and healing.  ",
      more: "The session starts with an optional foot bath and scrub. The session also addresses the shin and calf areas up to the knees.",
      pricing: "60 min - $100, 90 min - $140, 120 min - $170",
      appt: "true",
      benefits: "Clarity",
      avatarLetter: "M",
      pkgs: [
        {
          description: "1 x 30-minute = $50",
          value: {
            packageDesc: "1 x 30min Foot Massage",
            packagePrice: 50,
            sessions: 1,
            type: "service",
            used: false,
          },
        },
        {
          description: "1 x 60-minute = $90",
          value: {
            packageDesc: "1 x 60min Foot Massage",
            packagePrice: 90,
            sessions: 1,
            type: "service",
            used: false,
          },
        },
        {
          description: "3 x 30-minute = $135",
          value: {
            packageDesc: "3 x 30min Foot Massages",
            packagePrice: 135,
            sessions: 3,
            type: "service",
            used: false,
          },
        },
        {
          description: "3 x 60-minute = $255",
          value: {
            packageDesc: "3 x 60min Foot Massages",
            packagePrice: 255,
            sessions: 3,
            type: "service",
            used: false,
          },
        },
        {
          description: "10 x 30-minute = $400",
          value: {
            packageDesc: "10 x 30min Foot Massages",
            packagePrice: 400,
            sessions: 10,
            type: "service",
            used: false,
          },
        },
        {
          description: "10 x 60-minute = $750",
          value: {
            packageDesc: "10 x 60min Foot Massages",
            packagePrice: 750,
            sessions: 10,
            type: "service",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
    },
  ],
};

export default serviceListMassage;
