// Review that is from class project and delete

import { NavLink } from "react-router-dom";

function NavBar(props) {
  const links = props.links;
  return (
    <div>
      {links.map((link, index) => (
        <NavLink key={index} to={link.path} exact activeClassName="current">
          <li
          // onClick={() => {
          //   console.log("Menu has been clicked.");
          //   props.onMenuClick();
          // }}
          >
            {link.name}
          </li>
        </NavLink>
      ))}
    </div>
  );
}

export default NavBar;
