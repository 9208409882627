import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Header.css";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import Badge from "@mui/material/Badge";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";

const links = [
  { name: "Massage", path: "/massage" },
  { name: "Yoga", path: "/yoga" },
  { name: "Kids Yoga", path: "/kids-yoga" },
  { name: "Meditation", path: "/meditation" },
  { name: "Coaching", path: "/coaching" },
  { name: "Classes", path: "/schedule" },
  { name: "About Us", path: "/about-us" },
  { name: "Contact Us", path: "/contact-us" },
];

const accLinks = [
  { name: "Profile", path: "/profile" },
  { name: "My Appts", path: "/appointments" },
  { name: "My Classes", path: "/my-classes" },
  { name: "Sign Out", path: "/sign-out" },
];

const useStyles = makeStyles((theme) => ({
  rootHeader: {
    flexGrow: 1,
  },
  colorTitle: {
    color: "#005662",
  },
  signInButton: {
    // marginLeft: "16px",
    paddingLeft: "16px",
    fontWeight: "700",
    color: "#3f50b5",
  },
}));

function Header(props) {
  const history = useHistory();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);

  // Event handlers for main menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (pageURL) => {
    history.push(pageURL);
    setAnchorEl(null);
  };

  // Event handlers for account menu
  const handleAccMenu = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const handleAccMenuClick = (pageURL) => {
    if (pageURL === "/sign-out") {
      props.onLoggedOut().then((userLogOut) => {
        if (!userLogOut.err) {
          history.push("/welcome");
        } else {
          console.log("Logout failed!", userLogOut.errMsg);
        }
      });
    } else {
      history.push(pageURL);
    }
    setAnchorE2(null);
  };

  return (
    <header>
      <div className={classes.rootHeader}>
        <AppBar position="static" color="default">
          <Toolbar
            sx={{
              mt: 0.7,
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              aria-controls="simple-menu"
              onClick={handleClick}
            >
              <MenuIcon
                sx={{
                  fontSize: 30,
                }}
              />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {links.map((link, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleMenuClick(link.path)}
                >
                  {link.name}
                </MenuItem>
              ))}
            </Menu>
            <Typography
              sx={{
                ml: 1,
              }}
            >
              <Link
                component="button"
                onClick={() => handleMenuClick("/welcome")}
              >
                <img src="images/Logo_v5.svg" alt="" className="logo-image" />
              </Link>
            </Typography>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
              }}
              paddingLeft="12px"
              fontWeight="bold"
              fontFamily="Roboto"
              paddingBottom="6px"
            >
              <Link
                component="button"
                underline="none"
                fontSize="20px"
                fontWeight="700"
                color="#4fb3bf"
                onClick={() => handleMenuClick("/welcome")}
              >
                Yapura <span className={classes.colorTitle}> Wellness </span>
              </Link>
            </Typography>
            <div>
              <IconButton
                sx={{ marginRight: 2, fontWeight: "700", color: "primary" }}
                aria-label="shopping cart"
                // aria-controls="menu-appbar"
                // aria-haspopup="true"
                disabled={props.purchase.length < 1}
                onClick={() => {
                  history.push("/shopping-cart");
                }}
                color="inherit"
              >
                <Badge badgeContent={props.purchase.length} color="primary">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </div>
            {!props.logged ? (
              <Button
                sx={{ fontWeight: "700", color: "#3f50b5" }}
                onClick={() => {
                  history.push("/login");
                }}
              >
                <span className={classes.signInButton}>Sign In</span>
              </Button>
            ) : (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleAccMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorE2}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorE2)}
                  onClose={handleClose2}
                >
                  {" "}
                  {accLinks.map((link, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleAccMenuClick(link.path)}
                    >
                      {link.name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </div>
    </header>
  );
}

export default Header;
