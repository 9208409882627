import React, { useState, useEffect } from "react";
import * as Realm from "realm-web";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: "16px",
    },
  },
}));

// Flag to keep track if an error is generated from the confirmUser function.
let errorConfirm = false;

function EmailConfirmation() {
  const [errConfirm, setErrConfirm] = useState({
    show: false,
    error: false,
    errorMsg: "",
  });

  const classes = useStyles();
  const app = new Realm.App({ id: "yapura-wellness-cemes" });

  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const tokenId = params.get("tokenId");

  async function confirmUser() {
    try {
      let response = await app.emailPasswordAuth.confirmUser(token, tokenId);
      errorConfirm = false;
      return errorConfirm;
    } catch (err) {
      setErrConfirm({
        show: true,
        error: true,
        errorMsg: err.message.replace(/\([^()]*\)/g, ""),
      });
      console.log(err);
      console.log(errConfirm.errorMsg);
      errorConfirm = true;
      return errorConfirm;
    }
  }

  function confirmUserEmail() {
    confirmUser().then((errorConfirm) => {
      !errorConfirm
        ? setErrConfirm({
            show: true,
            error: false,
            errorMsg: "Email confirmed successfully",
          })
        : console.log("Error confirming email");
    });
  }

  // Running confirmUserEmail upon loading - hopefully preventing an infinite loop
  useEffect(() => {
    confirmUserEmail();
  }, []);

  console.log(errorConfirm);

  return (
    <div className={classes.root}>
      {errConfirm.show && errConfirm.error && (
        <Alert severity="error">
          <AlertTitle>Your email confirmation failed.</AlertTitle>
          {errConfirm.errorMsg}
          {" - "}
          <Link href="/contact-us">
            <strong>Please click here to contact us.</strong>
          </Link>
        </Alert>
      )}
      {errConfirm.show && !errConfirm.error && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Your registration is complete! {errConfirm.errorMsg}—{" "}
          <Link href="/login">
            <strong>Please Sign In</strong>
          </Link>
        </Alert>
      )}
    </div>
  );
}

export default EmailConfirmation;
