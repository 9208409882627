import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "./AboutUs.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "60vh",
    flexGrow: 1,
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "/images/AliceandCarlos.jpg"
    })`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    justifyContent: "center",
    alignItems: "flex-end",
    background: "#eee",
  },
  mission: {
    width: "60%",
    color: "#e0f7fa",
    textAlign: "center",
    background: "rgba(0,0,0,0.2)",
    borderRadius: "16px",
    padding: "16px 48px",
  },
  welcome: {
    display: "flex",
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    background: "#ede7f6",
    minHeight: "7vh",
    justifyContent: "left",
    alignItems: "center",
  },
  paperContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: "16px",
      minWidth: 450,
      minHeight: "20vh",
    },
  },
  aboutContainerA: {
    paddingTop: "100px",
    paddingBottom: "50px",
    background: "#fbe9e7",
  },
  aboutStoryContainerA: {
    justifyContent: "left",
    paddingLeft: "48px",
    paddingRight: "48px",
    paddingBottom: "48px",
    background: "#fbe9e7",
  },
  aboutContainerC: {
    paddingTop: "100px",
    paddingBottom: "50px",
    background: "#b2ebf2",
  },
  aboutStoryContainerC: {
    justifyContent: "left",
    paddingLeft: "48px",
    paddingRight: "48px",
    paddingBottom: "48px",
    background: "#b2ebf2",
  },
  // aboutStory: {
  //   width: "90%",
  // },
}));

function AboutUs(props) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        <Typography className={classes.mission} variant="h6" paragraph>
          We share the same passion for personal development and well-being. We
          focus our skills to help you on your journey to wellbeing.{" "}
        </Typography>
        <div className={classes.paperContainer}></div>
      </div>
      {/* <Paper> */}
      <Grid container className={classes.aboutContainerA}>
        <Grid item sm={4}>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ ml: 2 }}
            //   position: "relative",
            //   right: { xs: 1, md: 1 },
            //   p: { xs: 3, md: 6 },
            //   pr: { md: 0 },
            // }}
          >
            <Box>
              <img
                src="images/Alice_Grasset.jpg"
                className="img-about-us-portrait"
                alt="Alice Grasset at Yapura Wellness"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item sm={8}>
          <Box
            display="flex"
            justifyContent="flex-start"
            // bgcolor="red"
            height="200px"
            sx={{ ml: 2, pl: { xs: 1 } }}
            // sx={{
            //   position: "relative",
            //   p: { xs: 2, md: 4 },
            //   pr: { md: 0 },
            // }}
          >
            <Box display="flex" alignItems="center">
              {/* <Typography
                  component="h1"
                  variant="h3"
                  color="inherit"
                  gutterBottom
                >
                  Alice Grasset
                </Typography> */}
              {/* <Typography
                variant="h5"
                color="inherit"
                paragraph
                alignItems="center"
              > */}
              <Typography component="div">
                <Box fontSize="1.3rem">About Alice Grasset</Box>
                <Box>
                  <strong>Services:</strong> Coaching, Yoga, Meditation
                </Box>
                <Box>
                  <strong>Favorite Yoga Pose:</strong> Lizzard
                </Box>
                <Box>
                  <strong>Music Influences:</strong> Damon Albarn, Devendra
                  Banhart, MC Yogi
                </Box>
                <Box>
                  <strong>Power Animal:</strong> Cat
                </Box>
              </Typography>
              {/* <Link variant="subtitle1" href="#">
                  Learn More
                </Link> */}
            </Box>{" "}
          </Box>
        </Grid>{" "}
      </Grid>
      <Grid container className={classes.aboutStoryContainerA}>
        {/* <Grid item md={12}> */}
        {/* <Box */}
        {/* // sx={{ */}
        {/* //   position: "relative",
        //   p: { xs: 3, md: 6 },
        //   pr: { md: 0 },
        // }} */}
        {/* > */}
        <Typography variant="h6">Alice's Story</Typography>
        <Typography variant="h7">
          When I was about 10 years old, I found a guided meditation program on
          the French public radio. Meditation was not popular at the time, and I
          was intrigued by the program. I followed the voice guiding me through
          the steps and I started to feel a deep relaxation and sense of peace.
          This experience had a profound effect on me and many years later as an
          adult, I started to look for techniques and modalities that would be
          bring me similar experiences and would support my personal
          development.
        </Typography>
        <br />
        <Typography variant="h7">
          While pursuing a corporate career in healthcare, I became passionate
          about understanding how our mind works and how thoughts create and
          influence our experiences. When applied using various tools, these
          principles can significantly improve our well-being and help us
          achieve our dreams.
        </Typography>
        <br />
        <Typography variant="h7">
          I am especially interested by techniques that connect us to our inner
          resources beyond the rational brain. When we open to our broader
          intelligence, we gain more clarity, and we access more creativity,
          wisdom and alignment. Since a few years, I moved towards a more
          holistic approach with the introduction of movement in my practice to
          explore how to promote a harmonious mind-body connection.
        </Typography>
        <br />
        <Typography variant="h7">
          My deepest intention is to help people reconnect with their unique
          magic and create fantastic lives for themselves. I am committed to
          provide you with my best caring support on your journey of
          self-awareness, self-care, self-compassion and self-acceptance.
        </Typography>
        {/* </Box> */}
      </Grid>
      {/* </Grid>{" "} */}
      <Grid container className={classes.aboutContainerC}>
        <Grid item sm={4}>
          <Box display="flex" justifyContent="flex-end" sx={{ ml: 2 }}>
            <Box>
              <img
                src="images/Carlos_Yapura.jpg"
                className="img-about-us-portrait"
                alt="Carlos Yapura at Yapura Wellness"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item sm={8}>
          <Box
            display="flex"
            justifyContent="flex-start"
            height="200px"
            sx={{ ml: 2, pl: { xs: 1 } }}
          >
            <Box display="flex" alignItems="center">
              <Typography component="div">
                <Box fontSize="1.3rem">About Carlos Yapura</Box>
                <Box>
                  <strong>Services:</strong> Massage, Yoga, Meditation
                </Box>
                <Box>
                  <strong>Favorite Yoga Pose:</strong> Triangle
                </Box>
                <Box>
                  <strong>Music Influences:</strong> Krishna Das, The Beatles,
                  Daft Punk
                </Box>
                <Box>
                  <strong>Power Animal:</strong> Falcon
                </Box>
              </Typography>
            </Box>{" "}
          </Box>
        </Grid>{" "}
      </Grid>
      <Grid container className={classes.aboutStoryContainerC}>
        <Typography variant="h6">Carlos' Story</Typography>
        <Typography variant="h7">
          During a career in engineering, I started practicing yoga seeking a
          greater meaning and purpose for my life. I enjoyed the benefits of
          physical well-being and was also attracted to the yogic philosophy of
          a higher Self within. This led to an ongoing journey of finding a
          connection and alignment within myself.
        </Typography>
        <br />
        <Typography variant="h7">
          After a couple of years of study at the Dallas School of Metaphysics,
          I was motivated to learn more about healing. I was attracted to the
          restorative aspects of yoga and decided to pursue training in Thai
          massage in Thailand and back in the US. This opened up a new world for
          me.
        </Typography>
        <br />
        <Typography variant="h7">
          At One Aum Wellness Center (now Beautyhood) in Maryland, I was given
          the opportunity to practice and teach yoga as well as practice Thai
          massage and expand into Deep Tissue massage. This was an ideal
          laboratory for me to start realizing the power of aligning with our
          highest Self for the highest good of all.
        </Typography>
        <br />
        <Typography variant="h7">
          I am excited to continue my journey in North Carolina and keep
          learning about healing. By embracing the present moment and connecting
          with the highest good is my starting point for helping others in their
          own alignment as well.
        </Typography>
      </Grid>
    </div>
  );
}

export default AboutUs;
