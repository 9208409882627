import MyApptCard from "./MyApptCard";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import "./ServiceList.css";

const useStyles = makeStyles((theme) => ({
  cartTable: {
    margin: "16px 8px 8px",
    maxWidth: 500,
  },
}));

function MyAppts(props) {
  const classes = useStyles();
  const [myApptList, setApptList] = useState([]);
  const [myPackages, setPackages] = useState([]);

  useEffect(() => {
    props.onCall("viewRequests").then((result) => {
      console.log("result from getting appts ", result);
      setApptList(result.myApptsR);
      setPackages(result.myPackages);
    });
  }, []);

  //TODO: Need to implement functionality with packages on the database
  async function cancelAppt(date) {
    props.onCall("cancelRequest", date).then((result) => {
      console.log("result from getting appts ", result);
      setApptList(result.myApptsR);
      setPackages(result.myPackages);
    });
    // try {
    //   const enrollingStudent = await removeStudentSeq(clsInfo);
    //   console.log("removing student: ", enrollingStudent);
    //   return enrollingStudent;
    // } catch (err) {
    //   console.log("Failed to remove student", err.message);
    // }
    // setReread(!reread);
  }

  function toDateValue(timeIn) {
    let dateVal = new Date(timeIn);
    return dateVal.toDateString();
  }

  return (
    <div className="massage-box">
      <TableContainer component={Paper} className={classes.cartTable}>
        <Table sx={{ minWidth: 500 }} size="small" aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>My Packages</TableCell>
              <TableCell>Date Added</TableCell>
              <TableCell>Sessions Available</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myPackages.map((row, index) => (
              <TableRow key={row.packageDesc}>
                <TableCell>{row.packageDesc}</TableCell>
                <TableCell>{toDateValue(row.dateAdded)}</TableCell>
                <TableCell>{row.sessions - row.usage.length}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {myApptList.map((apptType) => (
        <MyApptCard
          key={apptType._id}
          id={apptType.userId}
          title={apptType.service}
          date={apptType.dateReq}
          dateConf={apptType.dateConf}
          onApptRemove={cancelAppt}
        />
      ))}
    </div>
  );
}

export default MyAppts;
