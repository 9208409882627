import React, { useState, useEffect } from "react";
import ClassCard from "./ClassCard";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./ClassSchedule.css";

// Initializing the indices selected on the pulldowns
let filter1 = "All";
let filter2 = "All";
let classesFull = [];
let classNames = [
  "All",
  "Teens Yoga",
  "Sweet Dreams",
  "Chair Yoga",
  "Vinyasa Flow",
  "Kids Yoga",
  "Tweens Yoga",
  "Renew",
  "Beginner's Yoga",
  "Guided Relaxation",
];
let classAttendance = ["All", "In-Person", "Online"];

function ClassSchedule(props) {
  const [reread, setReread] = useState([false]);
  const [classesFiltered, setClasses] = useState([]);
  const [filterVal1, setFilterVal1] = useState("All");
  const [filterVal2, setFilterVal2] = useState("All");
  // On first loadup read the active classes and then generate a list of classes
  useEffect(() => {
    setTimeout(() => {
      readActiveClasses().then((result) => {
        console.log("reading active classes - ", result);
      });
    }, 1000);
  }, []);

  // Reads active classes that have been booked at least once
  async function readActiveClasses() {
    try {
      props.onEnroll("viewClasses", {}).then((result) => {
        let classesF = result;
        setClasses(classesF);
        classesFull = classesF;
      });
      return "read class list";
    } catch (err) {
      console.log("Failed to obtain class schedule", err.message);
    }
  }

  async function filterClasses(classesF) {
    // Defaults to the full class list if indices are set to "All"
    let classesF1 = classesF;
    setClasses(classesF1);
    if (filter1 !== "All") {
      classesF1 = classesF.filter((classElem) => classElem.title === filter1);
      setClasses(classesF1);
    }
    // implementing the second filter by attendancd
    let classesF2 = classesF1;
    if (filter2 !== "All") {
      classesF2 = classesF1.filter(
        (classElem) => classElem.attendance === filter2
      );
      setClasses(classesF2);
    }
    return "filtered";
  }

  async function enrollStudentSeq(clsInfo) {
    console.log(clsInfo);
    props
      .onEnroll("enrollToClass", clsInfo)
      .then((result) => {
        console.log("result from function call to enroll: ", result.length);
        //Re-start whole sequence to update the class schedule
        let classesF = result;
        setClasses(classesF);
        classesFull = classesF;
        return filterClasses(result);
      })
      .then((result2) => {
        console.log("filtering classes again- ", result2);
        setReread(!reread);
      })
      .catch((error) => console.error(error));
  }

  async function enrollStudent(clsInfo) {
    try {
      const enrollingStudent = await enrollStudentSeq(clsInfo);
      console.log("enrolling student: ", enrollingStudent);
      setReread(!reread);
      return enrollingStudent;
    } catch (err) {
      console.log("Failed to log out", err.message);
    }
  }

  async function updateSelection1(event) {
    filter1 = event.target.value;
    setFilterVal1(filter1);
    filterClasses(classesFull).then((result) => {
      console.log("class-filtering 1: ", result);
    });
  }

  function updateSelection2(event) {
    filter2 = event.target.value;
    setFilterVal2(filter2);
    filterClasses(classesFull).then((result) => {
      console.log("class-filtering 2: ", result);
    });
  }

  return (
    <div className="">
      {!props.logged && (
        <Alert severity="info">
          Please sign in to see availability and to enroll
        </Alert>
      )}
      {/* Create pulldown controls */}
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: 500,
          marginTop: 2,
          marginBottom: 1,
          marginLeft: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="Class Name">Select a Class</InputLabel>
              <Select
                labelId="Class Name"
                id="Class Name"
                value={filterVal1}
                onChange={(e) => updateSelection1(e)}
                autoWidth
                label="Select a Class"
              >
                {classNames.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="Class Attendance">
                Select by Attendance
              </InputLabel>
              <Select
                labelId="Class Attendance"
                id="Class Attendance"
                value={filterVal2}
                onChange={(e) => updateSelection2(e)}
                autoWidth
                label="Select by attendance"
              >
                {classAttendance.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <div className="massage-box">
        {classesFiltered.map((cla, index) => (
          <ClassCard
            key={index}
            cInfo={cla}
            logged={props.logged}
            onEnroll={enrollStudent}
          />
        ))}
      </div>
    </div>
  );
}

export default ClassSchedule;
