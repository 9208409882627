const classList = [
  {
    key: 1,
    img: "images/yoga-meditation.jpg",
    title: "Rejuvenate",
    content:
      "Rejuvenate is a restorative class that uses gentle poses to allow the body to stretch under it's own weight.  Also thai massage is used to aid the stretching during a pose.  Must be ok to receive thai massage adjustments during this class.",
  },
  {
    key: 2,
    img: "images/yoga-meditation.jpg",
    title: "Vinyasa Flow I",
    content:
      "Vinyasa Flow I uses the sun-salute sequences to warm up the body and move progressively into strengthening poses and balancing poses",
  },
  {
    key: 3,
    img: "images/yoga-meditation.jpg",
    title: "Vinyasa Flow II",
    content:
      "Vinyasa Flow II starts with the foundation of Vinyasa Flow I to progress further into poses that may include backbends and inversions.",
  },
  {
    key: 4,
    img: "images/yoga-meditation.jpg",
    title: "Meditation",
    content:
      "Meditation uses Mindfullness Meditation practices that focuses on the fundamentals of meditation to build self-reflection, breath awareness, and relaxation.",
  },
];

export default classList;
