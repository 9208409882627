import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ShoppingCheckout from "./ShoppingCheckout";
import "./ShoppingCart.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.
//
// TODO: Save public key in an env file
const promise = loadStripe(
  "pk_live_51Jbd2CEibiqWGPlyuLn574cmgmxiPyMBGMiuQBUS6xLulmab2soymBGlQlSGrZhvmiceBlkTz3byhrRor3b0S14u00govLySbh"
);

function ShoppingCart(props) {
  return (
    <div className="App">
      <Elements stripe={promise}>
        <ShoppingCheckout
          onCall={props.onCall}
          cartItems={props.cartItems}
          onUpdate={props.onUpdate}
          email={props.email}
        />
      </Elements>
    </div>
  );
}

export default ShoppingCart;
