import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles((theme) => ({
  cartTable: {
    margin: "8px 0 16px",
  },
  cellDiscountFormat: {
    color: "#e53935",
  },
}));

export default function ShoppingList(props) {
  const classes = useStyles();

  function totalPrice(items) {
    return items
      .map(({ packagePrice }) => packagePrice)
      .reduce((sum, i) => sum + i, 0);
  }

  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }

  return (
    <TableContainer component={Paper} className={classes.cartTable}>
      <Table sx={{ minWidth: 400 }} size="small" aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell align="right">Price ($)</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.cartItems.map((row, index) => (
            <TableRow key={row.packageDesc}>
              <TableCell
                sx={{
                  color: row.type === "discount" && "#e53935",
                }}
              >
                {row.packageDesc}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: row.type === "discount" && "#e53935",
                }}
              >
                {ccyFormat(row.packagePrice)}
              </TableCell>
              {row.type !== "discount" && (
                <TableCell align="left">
                  <Tooltip title="Remove Item">
                    <IconButton
                      key={index}
                      size="small"
                      disabled={props.disableDelete}
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => props.onDelete(index)}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        <TableHead>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell align="right">
              {ccyFormat(totalPrice(props.cartItems))}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
}
