import "./ClassCard.css";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { red } from "@mui/material/colors";
import RemoveIcon from "@mui/icons-material/Remove";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    marginTop: 8,
    marginLeft: 8,
  },
  titles: {
    fontSize: 16,
    minHeight: 30,
    paddingBottom: 0,
    marginBottom: 0,
  },
  enroll: {
    backgroundColor: "#e3f2fd",
  },
}));

function MyClassesCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.titles}
        action={
          <Tooltip title="Remove" enterDelay={100}>
            <IconButton
              aria-label="settings"
              variant="soft"
              size="small"
              sx={{ bgcolor: red[100] }}
            >
              <RemoveIcon
                color="primary"
                onClick={(event) => {
                  props.onRemove(props.cInfo);
                }}
              />
            </IconButton>
          </Tooltip>
        }
        title={props.cInfo.date}
        subheader={`${props.cInfo.timeH}:  ${props.cInfo.timeM}`}
      />
      <CardActions disableSpacing>
        <div className="class-card-div">
          <p className="class-info-top">{props.cInfo.title}</p>
          <p className="class-info-bottom">{props.cInfo.duration}</p>
        </div>
        <div className="class-card-div">
          <p className="class-info-top">Instructor: {props.cInfo.teacher}</p>
          <p className="class-info-bottom">
            Attendance: {props.cInfo.attendance}
          </p>
        </div>
      </CardActions>
    </Card>
  );
}

export default MyClassesCard;
