import ServiceCard from "./ServiceCard";
import "./ServiceList.css";
import classList from "./ClassList.js";

function deleteItem(id) {
  console.log("Show more info on this service" + id);
}

function ClassListing() {
  return (
    <div className="massage-box">
      {classList.map((classType) => (
        <ServiceCard
          key={classType.key}
          id={classType.key}
          img={classType.img}
          title={classType.title}
          content={classType.content}
          onServiceRequest={deleteItem}
        />
      ))}
    </div>
  );
}

export default ClassListing;
