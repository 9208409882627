import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ServiceView from "./ServiceView";
import ServiceCard from "./ServiceCard";
import "./ServiceList.css";

const useStyles = makeStyles((theme) => ({
  welcome: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    background: "#fbe9e7",
    minHeight: "7vh",
    justifyContent: "center",
    alignItems: "center",
  },
  serviceCards: {
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function ServiceList(props) {
  const classes = useStyles();
  return (
    <div className="massage-box">
      <Container
        maxWidth="false"
        sx={{ display: "flex", alignItems: "center" }}
        className={classes.welcome}
      >
        <Typography component="div" variant="h6" align="center">
          {props.serviceList.mainTitle}
        </Typography>
      </Container>{" "}
      <ServiceView serviceContent={props.serviceList.serviceView} />
      <Container
        maxWidth="false"
        sx={{ display: "flex" }}
        className={classes.serviceCards}
      >
        {props.serviceList.list.map((service) => (
          <ServiceCard
            key={service.key}
            id={service.key}
            img={service.img}
            title={service.title}
            content={service.content}
            more={service.more}
            pricing={service.pricing}
            appt={service.appt}
            benefits={service.benefits}
            avatarLetter={service.avatarLetter}
            pkgs={service.pkgs}
            logged={props.logged}
            onAddCart={props.onAddCart}
          />
        ))}
      </Container>
    </div>
  );
}

export default ServiceList;
