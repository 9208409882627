const serviceListCoaching = {
  mainTitle: "Coaching",
  serviceView: [],
  list: [
    {
      key: 11,
      img: "images/Coaching_success.jpg",
      title: "Coaching",
      content:
        "Coaching is a process designed to help people maximize their potential, achieve their goals and live fulfilling lives.  Coaching is structured in a series of one-to-one meetings where a safe space is created for the client to reflect and gain clarity on who and where they are, what they want to achieve and what they can and are willing to do to reach their goals. Coaches do not tell clients what to do. Rather, using deep listening, observations and questions, coaches empower and enable clients to find their own answers and solutions. Through the coaching relationship, clients gain new insights, find different perspectives and build motivation to act.",
      more: "Examples of life coaching topics include: stress management, well-being and energy, time management, habits and routines, communications, conflicts, relationships, difficult decisions, personal goals, self-awareness and self-acceptance, life transitions, etc.  Book an initial consultation of 30 minutes with Alice free of charge to discuss how she can help you.",
      pricing: "Contact us for pricing",
      appt: "true",
      benefits: "Confidence Building",
      avatarLetter: "C",
      pkgs: [
        {
          description: "Please contact us",
          value: {
            packageDesc: "Please contact us",
            packagePrice: 0,
            sessions: 1,
            type: "service",
            used: false,
          },
        },
      ],
    },
  ],
};

export default serviceListCoaching;
