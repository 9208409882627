import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AccountCircle from "@mui/icons-material/AccountCircle";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "32px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: "8px",
    backgroundColor: "#3f50b5",
  },
  info: {
    margin: "8px 0 0",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "8px",
  },
  submit: {
    margin: "16px 0 0",
  },
  nameField: {
    margin: "16px 0 0",
  },
}));

// These flags errorForm and errorRegistration are used to avoid the "race" condition by using the values set using useState()
let errorForm = false;

function MyProfile(props) {
  const classes = useStyles();

  // Define useStates
  const [clientInfo, setClientInfo] = useState({
    firstname: "",
    lastname: "",
    address: "",
    phone: "",
    notificationEmail: false,
    notificationPhone: false,
    newsletter: false,
  });

  const [errMessage, setErrMessage] = useState({
    show: false,
    error: false,
    errorMsg: "",
  });

  const [enableUpdate, setEnableUpdate] = useState(false);

  // On first loadup read the active profile and save it to useState variable
  useEffect(() => {
    props.onCall("viewMyProfile", {}).then((result) => {
      if (result) {
        setClientInfo(result);
      }
    });
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    setErrMessage({
      error: false,
      errorMsg: "",
    });
    setEnableUpdate(true);
    let newvalue =
      event.target.type === "checkbox" ? event.target.checked : value;
    setClientInfo((prevValue) => {
      return {
        ...prevValue,
        [name]: newvalue,
      };
    });
  }

  async function callMongoDBFunction(userProfile) {
    try {
      const result = props.onCall("updateClientProfile", userProfile);
      setErrMessage((prevValue) => {
        return { ...prevValue, show: true };
      });
      return result;
    } catch (err) {
      setErrMessage({
        show: true,
        error: true,
        errorMsg: err.message.replace(/\([^()]*\)/g, ""),
      });
      console.log("Failed to update profile", err.message);
    }
  }

  function updateProfile(userData) {
    console.log("error on form elements = " + errorForm);
    if (!errorForm) {
      callMongoDBFunction(userData).then((result) => {
        setEnableUpdate(false);
        setClientInfo(result);
      });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container justifyContent="center" alignItems="center">
            <Grid>
              <Avatar className={classes.avatar}>
                <AccountCircle fontSize="large" />
              </Avatar>
            </Grid>
            <Grid>
              <Typography component="h1" variant="h5">
                My Preferences
              </Typography>
            </Grid>
          </Grid>
          <TextField
            className={classes.nameField}
            variant="outlined"
            margin="normal"
            fullWidth
            name="firstname"
            label="First Name"
            placeholder="First Name"
            type="text"
            id="firstname"
            autoComplete="first name"
            autoFocus
            size="small"
            value={clientInfo.firstname}
            onChange={handleChange}
          />
          <TextField
            className={classes.nameField}
            variant="outlined"
            margin="normal"
            fullWidth
            name="lastname"
            label="Last Name"
            placeholder="Last Name"
            type="text"
            id="lastname"
            autoComplete="last name"
            autoFocus
            size="small"
            value={clientInfo.lastname}
            onChange={handleChange}
          />
          <TextField
            className={classes.nameField}
            variant="outlined"
            margin="normal"
            fullWidth
            multiline
            rows={2}
            name="address"
            label="Address"
            placeholder="Address"
            type="text"
            id="address"
            autoComplete="address"
            autoFocus
            size="small"
            value={clientInfo.address}
            onChange={handleChange}
          />
          <TextField
            className={classes.nameField}
            variant="outlined"
            margin="normal"
            fullWidth
            name="phone"
            label="Phone Number"
            placeholder="Phone Number"
            type="text"
            id="phone"
            autoComplete="phone"
            autoFocus
            size="small"
            value={clientInfo.phone}
            onChange={handleChange}
          />
          <FormControlLabel
            className={classes.radio1}
            control={
              <Checkbox
                checked={clientInfo.notificationEmail}
                onChange={handleChange}
                name="notificationEmail"
                color="primary"
              />
            }
            label="Allow notifications by email"
            width="250px"
          />
          <FormControlLabel
            className={classes.radio1}
            control={
              <Checkbox
                checked={clientInfo.notificationPhone}
                onChange={handleChange}
                name="notificationPhone"
                color="primary"
              />
            }
            label="Allow notifications by text on phone"
            width="250px"
          />
          <FormControlLabel
            className={classes.radio1}
            control={
              <Checkbox
                checked={clientInfo.newsletter}
                onChange={handleChange}
                name="newsletter"
                color="primary"
              />
            }
            label="Sign up to our newsletter"
            width="250px"
          />
          <Button
            disabled={!enableUpdate}
            type="submit"
            fullWidth
            variant="contained"
            // style={{ backgroundColor: "#577c5c", color: "white" }}
            color="primary"
            className={classes.submit}
            onClick={(event) => {
              updateProfile(clientInfo);
              event.preventDefault();
            }}
          >
            Update
          </Button>
          {errMessage.error && errMessage.show && (
            <Alert severity="error">{errMessage.errorMsg}</Alert>
          )}
          {!errMessage.error && errMessage.show && (
            <Alert severity="success">Your profile has been updated.</Alert>
          )}
        </form>
      </div>
    </Container>
  );
}

export default MyProfile;
