import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as Realm from "realm-web";
import Header from "./Header";
import Footer from "./Footer";
import Classes from "./Classes";
import ServiceForm from "./ServiceForm";
import ClassSchedule from "./ClassSchedule";
import Login from "./Login";
import Register from "./Register";
import EmailConfirmation from "./EmailConfirmation";
import RegisterInstructions from "./RegisterInstructions";
import ContactUs from "./ContactUs";
import RequestPasswordReset from "./RequestPasswordReset";
import PasswordReset from "./PasswordReset";
import Welcome from "./Welcome";
import serviceListMassage from "./ServiceListMassage.js";
import serviceListCoaching from "./ServiceListCoaching.js";
import serviceListYoga from "./ServiceListYoga.js";
import serviceListKidsYoga from "./ServiceListKidsYoga.js";
import serviceListMeditation from "./ServiceListMeditation.js";
import ServiceList from "./ServiceList";
import myApptList from "./MyApptList.js";
import MyAppts from "./MyAppts";
import MyClasses from "./MyClasses";
import MyProfile from "./MyProfile";
import AboutUs from "./AboutUs";
import ShoppingCart from "./ShoppingCart";

let idNote = 0;
// TODO: Save app id in an env file
const app = new Realm.App({ id: "yapura-wellness-cemes" });
//TODO:  Place app id in an .env file
const appPublic = new Realm.App({ id: "yapura-wellness-contact-us-kgpii" });
var user = {};
var userAnon = {};

function App() {
  const [auth, setAuth] = useState(false);
  const [cart, setCart] = useState([]);
  const [notes, setItems] = useState([]);
  const [userStats, setUserStats] = useState({ email: "" });

  async function loginAnonymous() {
    // Create an anonymous credential
    const credentialsAnon = Realm.Credentials.anonymous();
    try {
      // Authenticate the user
      userAnon = await appPublic.logIn(credentialsAnon);
      console.log("app current user id = ", appPublic.currentUser.id);
      return user;
    } catch (err) {
      console.log("Failed to log in", err.message.replace(/\([^()]*\)/g, ""));
    }
  }

  // On first loadup sign in as anonymous user
  useEffect(() => {
    loginAnonymous();
  }, []);

  //TODO: ✓1) add useEffect to login anonymous user and keep that as default user
  //      ✓2) if valid user logs in, then use flag to use this user instead
  //      ✓3) when user buys a class(es) or a service package, store on user collection
  //      ✓4) classes - add note to sign-in to see class availability
  //      ✓5) use popovers to remind users to sign-in to request an appt or add a class or buy an item
  //      6) my classes - display how many classes a user has available
  //      7) my appts - display how many service session a user has available
  //      ✓8) when user logs out, go back to the anonymous user
  //      9) display consistent fonts on services on "see more" expansion
  //     ✓10) fix margins/padding to look like welcome or contact us links
  //     ✓11) update with new pictures
  //     ✓12) add all classes on the proposed class schedule
  //     ✓13) add Carlos' bio to "about us"
  //     ✓14) review text and services for massage
  //     ✓15) review text and services for yoga
  //     ✓16) review text and services for meditation
  //     ✓17) review test and services for coaching
  //     ✓18) upload web page to test hosting on Realm
  //     ✓19) ask Florence and Sean to test web page
  //     20) Make list of all items needed for next revision
  //     21) Make a schedule for next revision and release date
  //     22) Remove email & password from useState Variable in Login and Register
  //     23) Fill email field if user logged-in on Contact-Us form

  function saveUserInfo(info) {
    setUserStats(info);
  }

  //Function to login user
  async function loginEmailPassword(userInfo) {
    const credentials = Realm.Credentials.emailPassword(
      userInfo.email,
      userInfo.password
    );
    try {
      // Authenticate the user
      user = await app.logIn(credentials);
      setAuth(true);
      setUserStats({
        email: user._profile.data.email,
      });
      return { err: false, errMsg: "" };
    } catch (err) {
      console.log("App: Failed to log in: ", err.message);
      return { err: true, errMsg: err.message.replace(/\([^()]*\)/g, "") };
    }
  }

  //Function to logout user
  async function logoutEmailPassword() {
    try {
      await user.logOut();
      setAuth(false);
      return { err: false, errMsg: "" };
    } catch (err) {
      console.log("App: Failed to log out: ", err.message);
      return { err: true, errMsg: err.message.replace(/\([^()]*\)/g, "") };
    }
  }

  //Function call from user
  async function userCallFunction(functionName, payload) {
    try {
      let result;
      if (auth) {
        let dataFunction = { userId: user.id, data: payload };
        result = await user.callFunction(functionName, dataFunction);
      } else {
        let dataFunction = { userId: userAnon.id, data: payload };
        result = await userAnon.callFunction(functionName, dataFunction);
      }
      return result;
    } catch (err) {
      console.log(
        "Failed to call function",
        err.message,
        err.message.replace(/\([^()]*\)/g, "")
      );
    }
  }

  //TODO: Verify if not needed to be deleted
  function addItem(noteInfo) {
    setItems((prevItems) => {
      idNote += 1;
      return [
        ...prevItems,
        {
          key: idNote,
          id: idNote,
          title: noteInfo.noteTitle,
          content: noteInfo.noteContent,
        },
      ];
    });
    console.log(notes);
  }

  function addToCart(item) {
    //Add item to cart list
    setCart((prevItems) => {
      return [...prevItems, item];
    });
    console.log("item added to cart:", cart);
  }

  return (
    <Router>
      <Header onLoggedOut={logoutEmailPassword} logged={auth} purchase={cart} />
      <Switch>
        <Route path="/" exact>
          <Welcome />
        </Route>
        <Route path="/welcome">
          <Welcome />
        </Route>
        {/* <Route
          path={[ "/services", "/massage", "/yoga", "/meditation", "/coaching", "/appointments", ]}>
          <Services onAdded={addItem} onCallFunc={userCallFunction} />
        </Route> */}
        <Route path="/appointments">
          <MyAppts myApptList={myApptList} onCall={userCallFunction} />
        </Route>
        <Route path="/my-classes">
          <MyClasses onCall={userCallFunction} />
        </Route>
        <Route path="/profile">
          <MyProfile onCall={userCallFunction} />
        </Route>
        <Route path="/shopping-cart">
          <ShoppingCart
            onCall={userCallFunction}
            cartItems={cart}
            onUpdate={setCart}
            email={userStats.email}
          />
        </Route>
        <Route path="/massage">
          <ServiceList
            serviceList={serviceListMassage}
            logged={auth}
            onAddCart={addToCart}
          />
        </Route>
        <Route path="/coaching">
          <ServiceList
            serviceList={serviceListCoaching}
            logged={auth}
            onAddCart={addToCart}
          />
        </Route>
        <Route path="/yoga">
          <ServiceList
            serviceList={serviceListYoga}
            logged={auth}
            onAddCart={addToCart}
          />{" "}
        </Route>
        <Route path="/kids-yoga">
          <ServiceList
            serviceList={serviceListKidsYoga}
            logged={auth}
            onAddCart={addToCart}
          />
        </Route>
        <Route path="/meditation">
          <ServiceList
            serviceList={serviceListMeditation}
            logged={auth}
            onAddCart={addToCart}
          />
        </Route>
        <Route path="/classes">
          <Classes onAdded={addItem} />
        </Route>
        <Route path="/request">
          <ServiceForm onRequest={userCallFunction} email={userStats.email} />
        </Route>
        <Route path="/schedule">
          <ClassSchedule logged={auth} onEnroll={userCallFunction} />
        </Route>
        <Route path="/login">
          <Login onLogin={loginEmailPassword} />
        </Route>
        <Route path="/register">
          <Register onRegistration={saveUserInfo} />
        </Route>
        <Route path="/email-confirmation">
          <EmailConfirmation />
        </Route>
        <Route path="/register-instructions">
          <RegisterInstructions email={userStats.email} />
        </Route>
        <Route path="/contact-us">
          <ContactUs onCall={userCallFunction} />
        </Route>
        <Route path="/request-password-reset">
          <RequestPasswordReset />
        </Route>
        <Route path="/password-reset">
          <PasswordReset />
        </Route>
        <Route path="/about-us">
          <AboutUs />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
