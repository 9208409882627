import ClassListing from "./ClassListing";
import NavBar from "./NavBar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import "./Services.css";
// double check this is not needed and remove from code set

const links = [
  { name: "Listing", path: "/class-listing" },
  { name: "Schedule", path: "/schedule" },
];

function Classes(props) {
  return (
    <Router>
      <div>
        <div className="nav-box">
          <NavBar links={links} />
        </div>
        <Switch>
          <Route path="/classes">
            <div className="service-box">
              <h2 className="logo-text">Welcome to our Classes</h2>
              <br />
              <h3 className="logo-text">
                Select the schedule on the left to enroll to a class.
              </h3>
            </div>
          </Route>
          {/* Routing all Service Components */}
          <Route path="/class-listing">
            <ClassListing />
          </Route>
          <Route path="/schedule">
            <p>Insert here a table of classes</p>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default Classes;
