import React from "react";

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer>
      <p style={{ marginTop: 40 }}>Copyright © {year} Yapura Wellness</p>
      {/* <table width="100%">
        <tr>
          <td style={{ paddingTop: 45, paddingLeft: 20 }}>
            <a href="https://www.yapurawellness.com">
              <img
                src="https://mcusercontent.com/5185138000d94eb274795a34b/images/eafffa3a-891b-b784-1b40-049a17d9a8de.png"
                alt=""
                width="100"
              />
            </a>
            <p style="padding: 5px 0 3px">Yapura Wellness</p>
            <p style="padding: 3px">
              156 Mine Lake Court, Suite 100, Raleigh, NC 27615
            </p>
            <a href="http://www.facebook.com/yapurawellness">
              <img
                src="https://cdn-images.mailchimp.com/icons/social-block-v2/color-facebook-48.png"
                alt="Facebook"
                title="Facebook"
                class="social"
              />
            </a>
            <a href="http://instagram.com/yapurawellness">
              <img
                src="https://cdn-images.mailchimp.com/icons/social-block-v2/color-instagram-48.png"
                alt="Instagram"
                title="Instagram"
                class="social"
              />
            </a>
            <a href="http://www.linkedin.com/company/yapura-wellness">
              <img
                src="https://cdn-images.mailchimp.com/icons/social-block-v2/color-linkedin-48.png"
                alt="linkedin"
                title="linkedin"
                class="social"
              />
            </a>
            <a href="https://www.youtube.com/channel/UCIyzsF3NvuAa2mBqnMvxNkw">
              <img
                src="https://cdn-images.mailchimp.com/icons/social-block-v2/color-youtube-48.png"
                alt="YouTube"
                title="YouTube"
                class="social"
              />
            </a>
            <a href="mailto:alice@yapurawellness.com">
              <img
                src="https://cdn-images.mailchimp.com/icons/social-block-v2/color-forwardtofriend-48.png"
                alt="Email"
                title="Email"
                class="social"
              />
            </a>
          </td>
        </tr>
      </table> */}
    </footer>
  );
}

export default Footer;
