import React, { useState } from "react";
import "./ServiceForm.css";
import ServiceFormDayTime from "./ServiceFormDayTime";
import serviceFormDayTimeList from "./ServiceFormDayTimeList.js";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import ScheduleIcon from "@mui/icons-material/Schedule";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "32px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: "8px",
    backgroundColor: "#3f50b5",
  },
  info: {
    margin: "8px 0 0",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "8px",
  },
  submit: {
    margin: "16px 0 0",
  },
  nameField: {
    margin: "4px 0 0",
  },
  formControl: {
    margin: "16px",
    // width: "100%",
    width: "190px",
  },
  spinProgress: {
    position: "relative",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
  },
}));

function ServiceForm(props) {
  const classes = useStyles();

  const [serviceInfo, setService] = useState({
    email: props.email,
    service: "",
    monday: false,
    tuesday: false,
    wednesday: false,
    friday: false,
    saturday: false,
    sunday: false,
    mondayTime: "",
    tuesdayTime: "",
    wednesdayTime: "",
    fridayTime: "",
    saturdayTime: "",
    sundayTime: "",
    message: "",
    active: true,
    dateConf: "To be confirmed",
  });

  const [errMessage, setErrMessage] = useState({
    show: false,
    error: false,
    errorMsg: "",
  });
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);

  function handleChange(event) {
    const { name, value } = event.target;
    let newvalue =
      event.target.type === "checkbox" ? event.target.checked : value;
    setService((prevValue) => {
      return {
        ...prevValue,
        [name]: newvalue,
      };
    });
    setErrMessage({
      show: false,
      error: false,
      errorMsg: "",
    });
    setDisabled(false);
  }

  function sendMessage(userData) {
    setProcessing(true);
    props.onRequest("addRequest", userData).then((result) => {
      console.log("result from function call ", result);
      //TODO: check error message handling compatible to other function calls
      if (result !== "1111") {
        setErrMessage({
          show: true,
          error: true,
          errorMsg:
            "There was an error posting this request.  Please try again later.",
        });
      } else {
        setErrMessage({
          show: true,
          error: false,
          errorMsg: "Success.",
        });
      }
      setProcessing(false);
    });
    setDisabled(true);
  }

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <form className={classes.form} noValidate>
            <Grid container justifyContent="center" alignItems="center">
              <Grid>
                <Avatar className={classes.avatar}>
                  <ScheduleIcon fontSize="large" />
                </Avatar>
              </Grid>
              <Grid>
                <Typography component="h1" variant="h5">
                  Appointment Request
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <Alert severity="info" className={classes.info}>
                  We will contact you within one business day. Otherwise, please
                  call or text us at (984) 900-1254 or (984) 900-1516.
                </Alert>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="select-service-label">Service</InputLabel>
                  <Select
                    labelId="select-service-label"
                    id="select-service"
                    name="service"
                    value={serviceInfo.service}
                    onChange={handleChange}
                    label="Service"
                  >
                    <MenuItem value={"Massage"}>Massage</MenuItem>
                    <MenuItem value={"Yoga"}>Yoga</MenuItem>
                    <MenuItem value={"Coaching"}>Coaching</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {serviceFormDayTimeList.map((daytime) => (
              <ServiceFormDayTime
                key={daytime.key}
                id={daytime.key}
                day={daytime.day}
                dayLabel={daytime.dayLabel}
                dayTime={daytime.dayTime}
                onChange={handleChange}
              />
            ))}
            <TextField
              className={classes.nameField}
              // error={messageValidation.error}
              // helperText={messageValidation.errorMsg}
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
              rows={2}
              name="message"
              label="Additional Info"
              placeholder="Add additional information for your request ..."
              type="text"
              id="message"
              autoComplete="current-message"
              value={serviceInfo.message}
              onChange={handleChange}
            />
            <Button
              type="submit"
              disabled={disabled}
              fullWidth
              variant="contained"
              // style={{ backgroundColor: "#577c5c", color: "white" }}
              color="primary"
              className={classes.submit}
              onClick={(event) => {
                sendMessage(serviceInfo);
                event.preventDefault();
              }}
            >
              Submit Request
            </Button>
            {processing && (
              <CircularProgress size={24} className={classes.spinProgress} />
            )}
            {errMessage.error && errMessage.show && (
              <Alert severity="error">{errMessage.errorMsg}</Alert>
            )}
            {!errMessage.error && errMessage.show && (
              <Alert severity="success">
                Your message has been sent! We will respond to your email or any
                other contact info you provided here.
              </Alert>
            )}
          </form>
        </div>
      </Container>
    </div>
  );
}

export default ServiceForm;
