import React from "react";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import ServiceViewFull from "./ServiceViewFull";
import ServiceViewHalf from "./ServiceViewHalf";
import ServiceViewThird from "./ServiceViewThird";

const useStyles = makeStyles((theme) => ({
  serviceViewCards: {
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    background: "#ffffee",
  },
}));

function ServiceView(props) {
  const classes = useStyles();

  return (
    <Container
      maxWidth="false"
      sx={{ display: "flex" }}
      className={classes.serviceViewCards}
    >
      {props.serviceContent.map((service) => (
        <div>
          {service.layout === "full" && (
            <ServiceViewFull content={service.content} />
          )}
          {service.layout === "half" && (
            <ServiceViewHalf content={service.content} />
          )}
          {service.layout === "third" && (
            <ServiceViewThird content={service.content} />
          )}
        </div>
      ))}
    </Container>
  );
}

export default ServiceView;
