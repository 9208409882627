import React, { useState } from "react";
import * as Realm from "realm-web";
import * as EmailValidator from "email-validator";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import AccountCircle from "@mui/icons-material/AccountCircle";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "64px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: "16px",
    backgroundColor: "#3f50b5",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "8px",
  },
  submit: {
    margin: "24px 0 16px",
  },
  emailField: {
    margin: "24px 0 0",
  },
}));

// These flags errorForm and errorEmail are used to avoid the "race" condition by using the values set using useState()
let errorForm = false;
let errorEmail = false;

function RequestPasswordReset(props) {
  const classes = useStyles();

  // TODO: Save app id in an env file
  const app = new Realm.App({ id: "yapura-wellness-cemes" });

  const [clientInfo, setClientInfo] = useState({ email: "" });

  const [emailValidation, setEmailValidation] = useState({
    error: false,
    errorMsg: "",
  });

  const [errEmail, setErrEmail] = useState({
    show: false,
    error: false,
    errorMsg: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setErrEmail({
      show: false,
      error: false,
      errorMsg: "",
    });
    setEmailValidation({
      error: false,
      errorMsg: "",
    });
    setClientInfo((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function checkValidation() {
    if (clientInfo.email.length < 1) {
      setEmailValidation((prevValue) => {
        return {
          ...prevValue,
          error: true,
          errorMsg: "Email can't be blank",
        };
      });
      errorForm = true;
    } else {
      errorForm = false;
    }

    if (!EmailValidator.validate(clientInfo.email)) {
      setEmailValidation({
        error: true,
        errorMsg: "Email has to be of form name@domain.com",
      });
      errorForm = true;
    }
    // Removing mongoDB error message if client trying to register again
    if (!errorForm) {
      setErrEmail({
        show: false,
        error: false,
        errorMsg: "",
      });
    }
  }

  async function requestPasswordEmail(userInfo) {
    try {
      // Authenticate the user
      const result = await app.emailPasswordAuth.sendResetPasswordEmail(
        userInfo.email
      );
      errorEmail = false;
      setErrEmail((prevValue) => {
        return { ...prevValue, show: true };
      });
      return { auth: result, err: errorEmail };
    } catch (err) {
      setErrEmail({
        show: true,
        error: true,
        errorMsg: err.message.replace(/\([^()]*\)/g, ""),
      });
      errorEmail = true;
      console.log("Failed to request password reset", err.message);
      return { auth: {}, err: errorEmail };
    }
  }

  async function requestEmail(userData) {
    checkValidation();
    console.log("error on form elements = " + errorForm);
    if (!errorForm) {
      requestPasswordEmail(userData).then((returned) => {
        if (!returned.err) {
          console.log("request returned: ", returned.auth);
          console.log("request returned type: ", typeof returned.auth);
        } else {
          console.log(typeof returned);
          console.log("Error Requesting Password Reset Email!");
        }
      });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container justifyContent="center">
            <Grid>
              <Avatar className={classes.avatar}>
                <AccountCircle fontSize="large" />
              </Avatar>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid>
              <Typography component="h1" variant="h5">
                Confirm your email
              </Typography>
            </Grid>
          </Grid>
          <TextField
            error={emailValidation.error}
            helperText={emailValidation.errorMsg}
            className={classes.emailField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
            value={clientInfo.email}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(event) => {
              requestEmail(clientInfo);
              event.preventDefault();
            }}
          >
            Request Password Reset
          </Button>
          {errEmail.error && errEmail.show && (
            <Alert severity="error">{errEmail.errorMsg}</Alert>
          )}
          {!errEmail.error && errEmail.show && (
            <Alert severity="success">
              A password reset email has been sent. Check your email and follow
              the link in that email to reset your password.
            </Alert>
          )}
        </form>
      </div>
    </Container>
  );
}

export default RequestPasswordReset;
