import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import AddIcon from "@mui/icons-material/Add";
import "./ClassCard.css";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    marginTop: 8,
    marginLeft: 8,
  },
  titles: {
    fontSize: 16,
    minHeight: 50,
    paddingBottom: 0,
  },
}));

function ClassCard(props) {
  const classes = useStyles();

  function showMins(mins) {
    if (mins === 0) {
      return "00";
    } else {
      return mins;
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.titles}
        action={
          props.logged && (
            <Tooltip title="Number of spots available" placement="left">
              <Badge
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                color="success"
                badgeContent={props.cInfo.spots}
                showZero
              >
                <Tooltip title="Enroll" enterDelay={500}>
                  <IconButton
                    aria-label="settings"
                    sx={{
                      bgcolor: "#e3f2fd",
                    }}
                  >
                    <AddIcon
                      color="primary"
                      onClick={(event) => {
                        props.onEnroll(props.cInfo);
                      }}
                    />
                  </IconButton>
                </Tooltip>{" "}
              </Badge>
            </Tooltip>
          )
        }
        title={props.cInfo.date}
        subheader={`${props.cInfo.timeH}:${showMins(props.cInfo.timeM)}`}
      />
      <CardActions disableSpacing>
        <div className="class-card-div">
          <p className="class-info-top">{props.cInfo.title}</p>
          <p className="class-info-bottom">{props.cInfo.duration}</p>
        </div>
        <div className="class-card-div">
          <p className="class-info-top">Instructor: {props.cInfo.teacher}</p>
          <p className="class-info-bottom">
            Attendance: {props.cInfo.attendance}
          </p>
        </div>
      </CardActions>
    </Card>
  );
}

export default ClassCard;
