import { makeStyles } from "@mui/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
  radio1: {
    display: "inline-flex",
    width: "200px",
  },
}));

function ServiceFormDayTime(props) {
  const classes = useStyles();

  return (
    <div className="service-form-day">
      <FormControlLabel
        className={classes.radio1}
        control={
          <Checkbox
            // checked={state.checkedB}
            onChange={props.onChange}
            name={props.day}
            color="primary"
          />
        }
        label={props.dayLabel}
        width="250px"
      />
      {/* <label className="radio" htmlFor={props.day}>
        <input
          className="radio-input"
          type="checkbox"
          name={props.day}
          id={props.day}
          onChange={props.onChange}
        />
        &nbsp;{props.dayLabel}
      </label> */}
      {/* <label className="day-time" htmlFor={props.dayTime}>
        Time(s):&nbsp;
        <input
          className="day-time-input"
          type="text"
          name={props.dayTime}
          id={props.dayTime}
          placeholder="Enter preferred time(s) ..."
          onChange={props.onChange}
        />
      </label> */}
      <TextField
        variant="outlined"
        margin="0"
        size="small"
        fullWidth
        name={props.dayTime}
        placeholder="Enter preferred time(s) ..."
        type="text"
        id={props.dayTime}
        autoComplete="preferred-times"
        onChange={props.onChange}
      />
    </div>
  );
}

export default ServiceFormDayTime;
