import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import WelcomeCard from "./WelcomeCard";
import welcomeInfo from "./WelcomeCardInfo.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "50vh",
    flexGrow: 1,
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "/images/Alice_cobra.jpg"
    })`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    // width: "100vw",,
    // height: "100vh",
  },
  // paper: {
  //   marginTop: theme.spacing(8),
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  // },
  valueProposition: {
    color: "#ffffff",
    background: "rgba(0,0,0,0.3)",
    paddingTop: "56px",
  },
  valueReason: {
    color: "#ffffff",
    background: "rgba(0,0,0,0.3)",
    paddingBottom: "8px",
  },
  valuePromo: {
    color: "#dcedc8",
    background: "rgba(0,0,0,0.3)",
    paddingBottom: "56px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  welcome: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    background: "#fbe9e7",
    minHeight: "10vh",
    alignItems: "center",
    justifyContent: "center",
  },
  serviceCards: {
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "380px",
  },
}));

function Welcome(id) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Typography
              className={classes.valueProposition}
              component="h1"
              variant="h4"
              align="center"
            >
              Nurture yourself
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.valueReason}
              component="h1"
              variant="h5"
              align="center"
            >
              yoga, massage, meditation, coaching
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.valuePromo}
              component="h1"
              variant="h6"
              align="center"
            >
              {welcomeInfo.announcement}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Container
        maxWidth="false"
        sx={{ display: "flex" }}
        className={classes.welcome}
      >
        <Typography component="h1" variant="body1" align="center">
          {welcomeInfo.subtitle}
        </Typography>
      </Container>
      <Container
        maxWidth="false"
        sx={{ display: "flex" }}
        className={classes.serviceCards}
      >
        {welcomeInfo.list.map((service) => (
          <WelcomeCard
            key={service.key}
            id={service.key}
            img={service.img}
            title={service.title}
            value={service.value}
            path={service.path}
          />
        ))}
      </Container>
    </div>
  );
}

export default Welcome;
