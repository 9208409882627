import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  paragraphText: {
    fontFamily: "Open Sans",
    marginBottom: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  cardParagraph: {
    fontFamily: "Open Sans",
    marginTop: "0px",
    marginBottom: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
    backgroundColor: "#e8eaf6",
  },
}));

function ServiceViewFull(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <Container maxWidth="false">
      <Grid container>
        {props.content.map((service) => (
          <Grid item xs={12} sx={{ p: "8px" }}>
            {service.contentStyle === "text" && (
              <Typography
                variant="h6"
                fontSize="16px"
                align="center"
                color="text.secondary"
                className={classes.paragraphText}
              >
                {service.textContent}
              </Typography>
            )}
            {service.contentStyle == "text-card" && (
              <Card
                className={classes.cardParagraph}
                sx={{
                  minWidth: "400px",
                  bgcolor: service.cardColor,
                }}
                variant={service.cardOutline}
              >
                <CardContent
                  sx={{
                    padding: "24px",
                  }}
                >
                  <Typography align="center" variant="h5" component="div">
                    {service.subTitle}
                  </Typography>
                  <Typography align="center" color="#424242">
                    {service.textContent}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ServiceViewFull;
