import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import ImageListItemBar from "@mui/material/ImageListItemBar";

const useStyles = makeStyles({
  root: {
    display: "flex",
    minWidth: "360px",
    margin: "10px 10px 0",
    "&:hover": {
      transform: "scale(1.04, 1.04)",
    },
  },
  media: {
    height: 220,
    "&:hover": {},
  },
  title: {
    color: "#ffffff",
    fontSize: "24px",
    "&:hover": {
      color: "inherit",
    },
  },

  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
});

export default function WelcomeCard(props) {
  const classes = useStyles();

  return (
    <Card sx={{ display: "flex" }} className={classes.root}>
      <CardActionArea component={Link} to={props.path}>
        <CardMedia
          className={classes.media}
          image={props.img}
          title={props.title}
          alt="test1"
        />
        <ImageListItemBar
          title={props.title}
          classes={{
            root: classes.titleBar,
            title: classes.title,
          }}
        />
      </CardActionArea>
    </Card>
  );
}
