import React, { useState } from "react";
import * as Realm from "realm-web";
import * as EmailValidator from "email-validator";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import AccountCircle from "@mui/icons-material/AccountCircle";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "64px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: "16px",
    backgroundColor: "#0dbd24",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "8px",
  },
  submit: {
    margin: "24px 0 16px",
  },
  emailField: {
    margin: "40px 0 0",
  },
}));

// These flags errorForm and errorRegistration are used to avoid the "race" condition by using the values set using useState()
let errorForm = false;
let errorRegistration = false;

function Register(props) {
  const history = useHistory();
  const classes = useStyles();

  // TODO: Save app id in an env file
  const app = new Realm.App({ id: "yapura-wellness-cemes" });

  const [clientInfo, setClientInfo] = useState({ email: "", password: "" });

  const [emailValidation, setEmailValidation] = useState({
    error: false,
    errorMsg: "",
  });
  const [pwdValidation, setPwdValidation] = useState({
    error: false,
    errorMsg: "",
  });
  const [errRegistration, setErrRegistration] = useState({
    error: false,
    errorMsg: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setErrRegistration({
      error: false,
      errorMsg: "",
    });
    name === "email"
      ? setEmailValidation({
          error: false,
          errorMsg: "",
        })
      : setPwdValidation({
          error: false,
          errorMsg: "",
        });
    setClientInfo((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function checkValidation() {
    if (clientInfo.email.length < 1) {
      setEmailValidation((prevValue) => {
        return {
          ...prevValue,
          error: true,
          errorMsg: "Email can't be blank",
        };
      });
      errorForm = true;
    } else {
      errorForm = false;
    }

    if (!EmailValidator.validate(clientInfo.email)) {
      setEmailValidation({
        error: true,
        errorMsg: "Email has to be of form name@domain.com",
      });
      errorForm = true;
    }
    if (clientInfo.password.length < 8) {
      setPwdValidation({
        error: true,
        errorMsg: "Please use 8 or more characters",
      });
      errorForm = true;
    }
    // Removing mongoDB error message if client trying to register again
    if (!errorForm) {
      setErrRegistration({
        error: false,
        errorMsg: "",
      });
    }
  }

  async function signUpUser(registerData) {
    try {
      let response = await app.emailPasswordAuth.registerUser(
        registerData.email,
        registerData.password
      );
      errorRegistration = false;
      return errorRegistration;
    } catch (err) {
      setErrRegistration({
        error: true,
        errorMsg: err.message.replace(/\([^()]*\)/g, ""),
      });
      errorRegistration = true;
      return errorRegistration;
    }
  }

  function registerUser(userData) {
    checkValidation();
    console.log("error on form elements = " + errorForm);
    if (!errorForm) {
      signUpUser(userData).then((errorRegistration) => {
        !errorRegistration
          ? history.push("/register-instructions")
          : console.log("Error registering user");
      });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container justifyContent="center">
            <Grid>
              <Avatar className={classes.avatar}>
                <AccountCircle fontSize="large" />
              </Avatar>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid>
              <Typography component="h1" variant="h5">
                Register
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <Link href="/login" variant="body2">
                {"Already have an account? Sign in here"}
              </Link>
            </Grid>
          </Grid>
          <TextField
            error={emailValidation.error}
            helperText={emailValidation.errorMsg}
            className={classes.emailField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            placeholder="name@example.com"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
            value={clientInfo.email}
            onChange={handleChange}
          />
          <TextField
            error={pwdValidation.error}
            helperText={pwdValidation.errorMsg}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={clientInfo.password}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            // style={{ backgroundColor: "#577c5c", color: "white" }}
            color="primary"
            className={classes.submit}
            onClick={(event) => {
              registerUser(clientInfo);
              props.onRegistration({ email: clientInfo.email });
              event.preventDefault();
            }}
          >
            Register
          </Button>
          {errRegistration.error && (
            <Alert severity="error">{errRegistration.errorMsg}</Alert>
          )}
        </form>
      </div>
    </Container>
  );
}

export default Register;
