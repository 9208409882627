const serviceListYoga = {
  mainTitle: "Yoga",
  serviceView: [],
  list: [
    {
      key: 21,
      img: "images/Restorative_Yoga.jpg",
      title: "Renew",
      content:
        "Renew is a restorative class that uses gentle poses to allow the body to stretch using gravity.  Renew allows tense or fatigued muscles to relax and replenish.  ",
      more: "This class is 75 minutes.  In-person classes include adjustments to aid the stretching during a pose.  In-person classes are limited to 4 students. Online classes requires at least a bolster, block, and a strap.",
      pricing: "Select a single session or a package.",
      appt: "false",
      benefits: "Restoring",
      avatarLetter: "Y",
      pkgs: [
        {
          description: "1 Class = $20",
          value: {
            packageDesc: "1 x Yoga Class",
            packagePrice: 20,
            sessions: 1,
            type: "class",
            used: false,
          },
        },
        {
          description: "3 Classes = $50",
          value: {
            packageDesc: "3 x Yoga Classes",
            packagePrice: 50,
            sessions: 3,
            type: "class",
            used: false,
          },
        },
        {
          description: "10 Classes = $150",
          value: {
            packageDesc: "10 x Yoga Classes",
            packagePrice: 150,
            sessions: 10,
            type: "class",
            used: false,
          },
        },
        {
          description: "1 Online Class = $10",
          value: {
            packageDesc: "1 x Online Yoga Class",
            packagePrice: 10,
            sessions: 1,
            type: "class",
            used: false,
          },
        },
        {
          description: "10 Online Classes = $75",
          value: {
            packageDesc: "10 x Online Yoga Classes",
            packagePrice: 75,
            sessions: 10,
            type: "class",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
    },
    {
      key: 22,
      img: "images/Alice_cobra.jpg",
      title: "Beginner's Yoga",
      content:
        "This beginner's class integrates breathing, foundational yoga poses, and meditation.  This class is simple, effective, and meditative with a strong focus on the inner experience.",
      more: "This is a 60-minute in-person class limited to 3 students.",
      pricing: "Select a single session or a package.",
      appt: "false",
      benefits: "Building a Foundation",
      avatarLetter: "Y",
      pkgs: [
        {
          description: "1 Class = $20",
          value: {
            packageDesc: "1 x Yoga Class",
            packagePrice: 20,
            sessions: 1,
            type: "class",
            used: false,
          },
        },
        {
          description: "3 Classes = $50",
          value: {
            packageDesc: "3 x Yoga Classes",
            packagePrice: 50,
            sessions: 3,
            type: "class",
            used: false,
          },
        },
        {
          description: "10 Classes = $150",
          value: {
            packageDesc: "10 x Yoga Classes",
            packagePrice: 150,
            sessions: 10,
            type: "class",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
    },
    {
      key: 23,
      img: "images/Carlos_triangle.jpg",
      title: "Vinyasa Flow",
      content:
        "Vinyasa Flow uses sun-salute sequences to warm up the body and move progressively and mindfully from foundational poses to poses requiring more strength and balance.",
      more: "This is a 60-minute in-person class and is limited to 4 students and is well-suited for all levels from beginners to advanced.",
      pricing: "Select a single session or a package.",
      appt: "false",
      benefits: "Strengthening",
      avatarLetter: "Y",
      pkgs: [
        {
          description: "1 Class = $20",
          value: {
            packageDesc: "1 x Yoga Class",
            packagePrice: 20,
            sessions: 1,
            type: "class",
            used: false,
          },
        },
        {
          description: "3 Classes = $50",
          value: {
            packageDesc: "3 x Yoga Classes",
            packagePrice: 50,
            sessions: 3,
            type: "class",
            used: false,
          },
        },
        {
          description: "10 Classes = $150",
          value: {
            packageDesc: "10 x Yoga Classes",
            packagePrice: 150,
            sessions: 10,
            type: "class",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
    },
    {
      key: 24,
      img: "images/Chair_yoga_crescent.jpg",
      title: "Chair Yoga",
      content:
        "This class can help you alleviate muscles pain and stiffness due to long hours of sitting. It is a versatile way to stretch and strengthen the body with the convenience of using a chair as a prop.",
      more: "This is a 30-minute online class and requires a chair (without armrests or wheels) and is done over Zoom.",
      pricing: "Select a single session or a package.",
      appt: "false",
      benefits: "Mobility and Posture",
      avatarLetter: "Y",
      pkgs: [
        {
          description: "1 Class = $5",
          value: {
            packageDesc: "1 x Online Yoga Class",
            packagePrice: 5,
            sessions: 1,
            type: "class",
            used: false,
          },
        },
        {
          description: "10 Classes = $40",
          value: {
            packageDesc: "10 x Online Yoga Classes",
            packagePrice: 40,
            sessions: 10,
            type: "class",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
    },
    {
      key: 25,
      img: "images/Chair_yoga_nervous_system.jpg",
      title: "Chair Yoga for the Nervous System",
      content:
        "This class integrates slow movements, breathing and meditation to gently stimulate and sooth the brain and the nervous system. Suitable for anyone regardless of age or health condition.",
      more: "This class is especially beneficial for elders who want to be more active, people who have a chronic disease or health limitation, people who have mobility or flexibility issues and people struggling with stress and anxiety. This class is 60 minutes long.  This class has an in-person session as well as an online session.  The in-person classes is limited to 5 students. The online class requires a chair and is done over Zoom.",
      pricing: "Select a single session or a package.",
      appt: "false",
      benefits: "Interoception and Neuroplasticity",
      avatarLetter: "Y",
      pkgs: [
        {
          description: "1 Class = $20",
          value: {
            packageDesc: "1 x Yoga Class",
            packagePrice: 20,
            sessions: 1,
            type: "class",
            used: false,
          },
        },
        {
          description: "3 Classes = $50",
          value: {
            packageDesc: "3 x Yoga Classes",
            packagePrice: 50,
            sessions: 3,
            type: "class",
            used: false,
          },
        },
        {
          description: "10 Classes = $150",
          value: {
            packageDesc: "10 x Yoga Classes",
            packagePrice: 150,
            sessions: 10,
            type: "class",
            used: false,
          },
        },
        {
          description: "1 Online Class = $10",
          value: {
            packageDesc: "1 x Online Yoga Class",
            packagePrice: 10,
            sessions: 1,
            type: "class",
            used: false,
          },
        },
        {
          description: "10 Online Classes = $75",
          value: {
            packageDesc: "10 x Online Yoga Classes",
            packagePrice: 75,
            sessions: 10,
            type: "class",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
    },
    {
      key: 26,
      img: "images/Yoga_private.jpg",
      title: "Yoga Private",
      content:
        "A Yoga Private session is for any student to progress in the practice of yoga with a more personalized focus.  This class is also great to work on chronic or persistent conditions.",
      more: "A Yoga Private session is suitable for all levels from beginners to advanced practitioners. Online classes will be done over Zoom.",
      pricing: "Select a single session or a package.",
      appt: "true",
      benefits: "Focus",
      avatarLetter: "Y",
      pkgs: [
        {
          description: "1 Private Class = $80",
          value: {
            packageDesc: "1 x Yoga Private Class",
            packagePrice: 80,
            sessions: 1,
            type: "service",
            used: false,
          },
        },
        {
          description: "3 Private Classes = $225",
          value: {
            packageDesc: "3 x Yoga Private Classes",
            packagePrice: 225,
            sessions: 3,
            type: "service",
            used: false,
          },
        },
        {
          description: "10 Private Classes = $700",
          value: {
            packageDesc: "10 x Yoga Private Classes",
            packagePrice: 700,
            sessions: 10,
            type: "service",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
    },
  ],
};

export default serviceListYoga;
