import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    margin: "20px 20px 0",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: "#d1c4e9",
  },
  addCart: {
    marginLeft: "8px",
    backgroundColor: "#fff9c4",
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  backgroundColor: "#fff9c4",
}));

function ServiceCard(props) {
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [giftValue, setGiftValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [pkgSel, setPkgSel] = useState(props.pkgs[0].value);

  const handleChange = (event) => {
    setPkgSel(event.target.value);
    console.log(pkgSel);
  };

  function handleChangeGiftValue(event) {
    const { name, value } = event.target;
    let objIndex = props.pkgs.findIndex(
      (obj) => obj.description === "Gift Card"
    );
    props.pkgs[objIndex].value.packagePrice = parseFloat(value);
    setPkgSel(props.pkgs[objIndex].value);
    setGiftValue(value);
    console.log(props.pkgs[objIndex].value);
    console.log(pkgSel);
    console.log(value);
  }

  const handleAddCart = (event) => {
    //Send package information to cart
    if (props.logged) {
      console.log(pkgSel);
      props.onAddCart(pkgSel);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleReqService = (event) => {
    //Send package information to cart
    if (props.logged) {
      history.push("/request");
    } else {
      setAnchorE2(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="service" className={classes.avatar}>
            {props.avatarLetter}
          </Avatar>
        }
        // action={<IconButton aria-label="settings"><MoreVertIcon /></IconButton>}
        title={props.title}
        subheader={props.benefits}
      />
      <CardMedia
        className={classes.media}
        image={props.img}
        title="Service Offer"
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.content}
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{
          justifyContent: "space-between",
        }}
      >
        {props.appt === "true" ? (
          <>
            <Tooltip title="Form to request an appointment">
              <Button
                color="primary"
                variant="contained"
                size="small"
                aria-describedby="popover-message-reqAppt"
                onClick={handleReqService}
              >
                REQUEST APPOINTMENT
              </Button>
            </Tooltip>
            <Popover
              id="popover-message-reqAppt"
              open={open2}
              anchorEl={anchorE2}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <Typography sx={{ p: 2 }}>
                Please sign in to request an appointment.
              </Typography>
            </Popover>
          </>
        ) : (
          <Tooltip title="See class availability on the schedule">
            <Button
              color="primary"
              onClick={() => {
                history.push("/schedule");
              }}
            >
              SEE CLASS SCHEDULE
            </Button>
          </Tooltip>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography color="text.secondary" fontSize="15px">
            Pricing:
          </Typography>
          <Tooltip title={expanded ? "See less" : "See more and pricing"}>
            <IconButton size="small">
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                size="large"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="body2" color="textPrimary" component="p">
            More Info and Pricing:
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            paragraph
          >
            {props.more}
          </Typography>
          {props.pricing === "Contact us for pricing" ? (
            <>
              <Typography variant="body2" color="textPrimary" component="p">
                Pricing:
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                paragraph
              >
                {props.pricing}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                paragraph
              >
                Select a package that matches your needs:
              </Typography>
              <CardActions
                disableSpacing
                sx={{ justifyContent: "space-between" }}
              >
                <Box sx={{ minWidth: 245 }}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-label">
                      Choose a Package
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pkgSel}
                      label="Choose a Package"
                      onChange={handleChange}
                    >
                      {props.pkgs.map((pkgItem) => (
                        <MenuItem value={pkgItem.value}>
                          {" "}
                          {pkgItem.description}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {pkgSel.packageDesc === "Gift Card" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      size="small"
                      name="gift-card"
                      label="Gift Card Value"
                      placeholder="Enter Gift Card Amount"
                      type="number"
                      id="gift-card"
                      autoComplete="gift-card"
                      value={giftValue === 0 ? null : giftValue}
                      onChange={handleChangeGiftValue}
                    />
                  )}
                </Box>
                <Tooltip title="Add to Cart">
                  <IconButton
                    onClick={handleAddCart}
                    aria-label="add to cart"
                    aria-describedby="popover-message"
                    className={classes.addCart}
                  >
                    <AddShoppingCartIcon />
                  </IconButton>
                </Tooltip>
                <Popover
                  id="popover-message"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <Typography sx={{ p: 2 }}>
                    Please sign in to add items to your cart.
                  </Typography>
                </Popover>
              </CardActions>
            </>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default ServiceCard;
