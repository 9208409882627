const serviceFormDayTimeList = [
  {
    key: 1,
    day: "monday",
    dayLabel: "Monday",
    dayTime: "mondayTime",
  },
  {
    key: 2,
    day: "tuesday",
    dayLabel: "Tuesday",
    dayTime: "tuesdayTime",
  },
  {
    key: 3,
    day: "wednesday",
    dayLabel: "Wednesday",
    dayTime: "wednesdayTime",
  },
  {
    key: 4,
    day: "friday",
    dayLabel: "Friday",
    dayTime: "fridayTime",
  },
  {
    key: 5,
    day: "saturday",
    dayLabel: "Saturday",
    dayTime: "saturdayTime",
  },
  {
    key: 6,
    day: "sunday",
    dayLabel: "Sunday",
    dayTime: "sundayTime",
  },
];

export default serviceFormDayTimeList;
