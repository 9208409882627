const serviceListKidsYoga = {
  mainTitle: "Kids Yoga",
  serviceView: [
    {
      key: 411,
      layout: "full",
      content: [
        {
          contentStyle: "text-card",
          cardOutline: "none",
          // subTitle: "Yoga and mindfulness",
          subTitle: "",
          cardColor: "#ffffee",
          // deep orange light "#ffffee"
          textContent:
            "Join Alice on an engaging journey to discover yoga and mindfulness through music, games, stories, and fun yoga poses.",
        },
      ],
    },
    {
      key: 412,
      layout: "half",
      content: [
        {
          contentStyle: "text-image-left",
          subTitle:
            "Yoga and mindfulness are wonderful means of stress management.",
          img: "images/Camille Yoga.jpg",
          callToActionText: "Read more:",
          paragraphs: [
            {
              paragraphType: "text",
              textParagraph:
                "It is well-recognized that stress can contribute to a long list of physical and mental health problems. Left unchecked, long-term stress can weaken the immune system, cause high blood pressure, contribute to heart disease and obesity and lead to anxiety and depression among others. Stress is an issue for both adults and children. Data show that between 2016 and 2020, the number of children suffering from anxiety grew by 29 percent, and the number of children suffering from depression grew by 27 percent (1).",
            },
            {
              paragraphType: "text",
              textParagraph:
                "Yoga and mindfulness meditation have been considered for a long time as a wonderful means of stress management. The power of yoga for stress management lies in the combination of physical, emotional, and mental practices. Yoga poses and breathing exercises help cut through the physical manifestations of stress preparing the ground for relaxation, imagery techniques and meditation to address the emotional and mental components of stress.",
            },
            {
              paragraphType: "text",
              textParagraph:
                "A concrete example of how yoga and mindfulness can help children is with the management of stress associated with exams. Yoga and mindfulness will teach them different tools and strategies that they can then use for managing their stress around exams and ultimately support their performance.",
            },
            {
              paragraphType: "text",
              textParagraph:
                "Researchers have studied the impact of mindfulness on GPA and standardized test scores. In a study of two thousand middle school students, researchers have found that greater mindfulness had a significant correlation with academic achievement, including higher GPA and higher scores on standardized tests in mathematics and literacy (2).",
            },
            {
              paragraphType: "references",
              listContent: [
                "Lydie A. Lebrun-Harris et al., “Five-Year Trends in US Children's Health and well-being, 2016-2020”, JAMA Pediatr. March 14, 2022.",
                "Camilla Caballero et al., “Greater mindfulness is associated with better academic achievemet in middle school”, Mind, Brain, And Education 13, no.3 (2019): 157-66.",
              ],
            },
          ],
        },
        {
          contentStyle: "text-image-right",
          subTitle:
            "My approach is to introduce children to yoga and mindfulness through play.",
          img: "images/Kids_mindfulness.jpg",
          callToActionText: "Read more:",
          paragraphs: [
            {
              paragraphType: "text",
              textParagraph:
                "My approach is based on the classical study of yoga and transforms it into an engaging experience for children. I use music, dance, games, stories, and fun yoga poses to introduce them to yoga through play. Children have lots of fun in my classes (they are kids after all!) and at the same time, they learn about their body and mind and how the two interact with each other.",
            },
            {
              paragraphType: "text",
              textParagraph:
                "By learning these lifelong skills in a fun way, they are more likely to remember them outside of class and use them in stressful situations.",
            },
            {
              paragraphType: "text",
              textParagraph:
                "My goal is to inspire them to practice regularly to build their stress management skills, give them a sense of control of their inner state and establish a strong foundation that they can take into adulthood.",
            },
            {
              paragraphType: "text",
              textParagraph: "I offer the following group classes in person:",
            },
            {
              paragraphType: "bulletList",
              listContent: [
                "Kids from 4 to 8 years old",
                "Tweens from 9 to 12 years old",
                "Teens from 13 to 16 years old",
              ],
            },
            {
              paragraphType: "text",
              textParagraph:
                "In-person group classes at the studio have a maximum capacity of 4 children allowing for lots of attention. Since the number of participants is limited, please book in advance.",
            },
            {
              paragraphType: "text",
              textParagraph:
                "I also offer an online class for kids called 'Sweet Dreams' to help them prepare for bedtime. This online class is done over Zoom with a maximum of 12 children. Book your child’s spot to receive the Zoom link.",
            },
            {
              paragraphType: "text",
              textParagraph:
                "Private sessions are also available, including sessions focused on test anxiety management. Contact me to schedule.",
            },
          ],
        },
      ],
    },
    {
      key: 414,
      layout: "full",
      content: [
        {
          contentStyle: "text-card",
          cardOutline: "outlined",
          cardColor: "#e1f5fe",
          // light blue   "#e1f5fe"
          textContent:
            "Yoga and meditation are probably the most powerful tools we can teach to children to live a happy, healthy, and fulfilling life.  The benefits of yoga for children are observed at all levels.",
        },
      ],
    },
    {
      key: 415,
      layout: "third",
      content: [
        {
          contentStyle: "body",
          subTitle: "Physical",
          listContent: [
            "Builds posture, strength, flexibility, balance, and coordination skills",
            "Improves quality of sleep and supports the immune system and overall health",
          ],
        },
        {
          contentStyle: "soul",
          subTitle: "Emotional",
          listContent: [
            "Reduces stress and teaches how to cope with anxiety and be calm",
            "Boosts self-esteem and sense of wellbeing",
          ],
        },
        {
          contentStyle: "mind",
          subTitle: "Mental",
          listContent: [
            "Develops concentration and focus (add the two types of attention)",
            "Helps create healthy relationships",
          ],
        },
      ],
    },
    {
      key: 416,
      layout: "full",
      content: [
        {
          contentStyle: "text-card",
          cardOutline: "outlined",
          cardColor: "#fff7ff",
          //          deep purple light  "#fff7ff"  deep purple 100 "#d1c4e9"
          textContent:
            "Yoga and mindfulness can help you raise calm, confident and happy children.",
        },
      ],
    },
    // {
    //   key: 417,
    //   layout: "full",
    //   content: [
    //     {
    //       contentStyle: "text-image",
    //       img: "",
    //       textContent:
    //         "Yoga and mindfulness can help you raise calm, confident and happy children.",
    //     },
    //   ],
    // },
    {
      key: 418,
      layout: "half",
      content: [
        {
          contentStyle: "text-image-right",
          subTitle: "Family yoga is a fun bonding experience for all.",
          img: "images/Family_yoga_community.jpg",
          callToActionText: "Read more:",
          paragraphs: [
            {
              paragraphType: "text",
              textParagraph:
                "Join me to experience the benefits of yoga as a family and learn relaxation techniques you can continue to use together at home. Family yoga is a fun way to bond with your children while giving wonderful means of stress management to your entire family. Benefits of yoga and mindfulness are for all ages!",
            },
            {
              paragraphType: "text",
              textParagraph:
                "The family yoga class is best suited for children aged 4 to 12 years old and their caregivers. However, older kids are welcome as well! Family yoga classes are private. Contact me to schedule.",
            },
          ],
        },
        {
          contentStyle: "text-image-left",
          subTitle:
            "Yoga events can be customized for scouts and local community.",
          img: "images/Scouts_community.jpg",
          callToActionText: "Read more:",
          paragraphs: [
            {
              paragraphType: "text",
              textParagraph:
                "I would be happy to introduce yoga and mindfulness to your den/pack or to your local community organization. I will work with you to customize the class to your needs.",
            },
            {
              paragraphType: "text",
              textParagraph:
                "Yoga and mindfulness help children to be active, improve focus and attention, learn to relax and manage their emotions.",
            },
          ],
        },
      ],
    },
    {
      key: 416,
      layout: "full",
      content: [
        {
          contentStyle: "text-card",
          cardOutline: "nones",
          cardColor: "#fffde7",
          //          light yellow   "#fffde7"
          textContent: "Our  current offerings and pricing info",
        },
      ],
    },
  ],
  list: [
    {
      key: 421,
      img: "images/Kids_yoga_tree.jpg",
      title: "Kids Yoga",
      content:
        "Kids yoga is a group class for either kids, tweens, or teens for building confidence and happiness. For kids, there is an in-person and an online class.  For tweens and teens, there are in-person classes only.",
      more: "These classes are 45 minutes long.  In-person classes are limited to 4 students. Online classes are done over Zoom.",
      pricing: "Select a single session or a package.",
      appt: "false",
      benefits: "Inspiring",
      avatarLetter: "Y",
      pkgs: [
        {
          description: "1 Kids Yoga Class = $15",
          value: {
            packageDesc: "1 x Kids Yoga Class",
            packagePrice: 15,
            sessions: 1,
            type: "class",
            used: false,
          },
        },
        {
          description: "10 Kids Yoga Classes = $100",
          value: {
            packageDesc: "10 x Kids Yoga Classes",
            packagePrice: 100,
            sessions: 10,
            type: "class",
            used: false,
          },
        },
        {
          description: "1 Online Kids Yoga Class = $5",
          value: {
            packageDesc: "1 x Online Kids Yoga Class",
            packagePrice: 5,
            sessions: 1,
            type: "class",
            used: false,
          },
        },
        {
          description: "10 Online Kids Yoga Classes = $40",
          value: {
            packageDesc: "10 x Online Kids Yoga Classes",
            packagePrice: 40,
            sessions: 10,
            type: "class",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
    },
    {
      key: 422,
      img: "images/Family_yoga.jpg",
      title: "Family Yoga",
      content:
        "A Family Yoga class is best suited for children aged 4 to 12 years old and their caregivers.  Family yoga is a fun way to bond with your children",
      more: "This is a 60-minute in-person private class and is limited to 4 students.",
      pricing: "Select a single session or a package.",
      appt: "true",
      benefits: "Bonding",
      avatarLetter: "Y",
      pkgs: [
        {
          description: "1 Private Class = $80",
          value: {
            packageDesc: "1 x Family Yoga Class",
            packagePrice: 80,
            sessions: 1,
            type: "service",
            used: false,
          },
        },
        {
          description: "3 Private Classes = $225",
          value: {
            packageDesc: "3 x Family Yoga Classes",
            packagePrice: 225,
            sessions: 3,
            type: "service",
            used: false,
          },
        },
        {
          description: "10 Private Classes = $700",
          value: {
            packageDesc: "10 x Family Yoga Classes",
            packagePrice: 700,
            sessions: 10,
            type: "service",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
    },
  ],
};

export default serviceListKidsYoga;
