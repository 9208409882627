const welcomeInfo = {
  mainTitle: "Welcome!",
  // announcement:
  //   "Summer Sale is here! 40% off on all massage sessions and yoga privates. This promotion is automatically applied on the cart till July 10th, 2022. Get your services today!",
  //   "Our One-Year Anniversary Sale is here! 20% off on all yoga and massage services. This promotion is automatically applied on the cart till October 31st, 2022. Get your services today!","
  //   "Year-End Sale is here! 20% off on all massage sessions and yoga privates. This promotion is automatically applied on the cart till January 6th, 2023. Get your services today!",
  announcement: "",
  subtitle:
    "We are happy to welcome you on our site.  We strive to provide an integrative approach for connecting mind and body.",
  list: [
    {
      key: 101,
      img: "images/Deep_Tissue.jpg",
      title: "Massage",
      value: "Restore",
      avatarLetter: "M",
      path: "/massage",
    },
    {
      key: 102,
      img: "images/Carlos_triangle.jpg",
      title: "Yoga",
      content: "Unblock",
      avatarLetter: "Y",
      path: "/yoga",
    },
    {
      key: 103,
      img: "images/Meditation.jpg",
      title: "Meditation",
      content: "Clear",
      avatarLetter: "M",
      path: "/meditation",
    },
    {
      key: 104,
      img: "images/Coaching_success.jpg",
      title: "Coaching",
      content: "Succeed",
      avatarLetter: "C",
      path: "/coaching",
    },
    {
      key: 105,
      img: "images/Kids_yoga_tree.jpg",
      title: "Kids Yoga",
      content: "Grow",
      avatarLetter: "K",
      path: "/kids-yoga",
    },
  ],
};

export default welcomeInfo;
