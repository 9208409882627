import React, { useState, useEffect } from "react";
import MyClassesCard from "./MyClassesCard";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Initializing
const useStyles = makeStyles((theme) => ({
  cartTable: {
    margin: "16px 8px 8px",
    maxWidth: 500,
  },
}));

function MyClasses(props) {
  const classes = useStyles();
  const [reread, setReread] = useState([false]);
  const [classesFiltered, setClasses] = useState([]);
  const [myPackages, setPackages] = useState([]);

  // On first loadup read the active classes and then generate a list of classes
  useEffect(() => {
    readActiveClasses().then((result) => {
      console.log("reading active classes - ", result);
    });
  }, []);

  // Reads active classes that have been booked at least once
  async function readActiveClasses() {
    try {
      props.onCall("viewMyClasses", {}).then((result) => {
        console.log("number of classes found: ", result.myClassesR.length);
        let classesF = result.myClassesR;
        setClasses(classesF);
        setPackages(result.myPackages);
      });
      return "read class list";
    } catch (err) {
      console.log("Failed to obtain class schedule", err.message);
    }
  }

  async function removeStudentSeq(clsInfo) {
    console.log(clsInfo);
    props
      .onCall("unEnrollFromClass", clsInfo)
      .then((result) => {
        console.log(
          "result from function call to remove: ",
          result.myClassesR.length
        );
        // update the class list
        let classesF = result.myClassesR;
        setClasses(classesF);
        setPackages(result.myPackages);
      })
      .catch((error) => console.error(error));
  }

  async function removeStudent(clsInfo) {
    try {
      const enrollingStudent = await removeStudentSeq(clsInfo);
      console.log("removing student: ", enrollingStudent);
      return enrollingStudent;
    } catch (err) {
      console.log("Failed to remove student", err.message);
    }
    setReread(!reread);
  }

  function toDateValue(timeIn) {
    let dateVal = new Date(timeIn);
    return dateVal.toDateString();
  }

  return (
    <div className="">
      <TableContainer component={Paper} className={classes.cartTable}>
        <Table sx={{ minWidth: 500 }} size="small" aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>My Packages</TableCell>
              <TableCell>Date Added</TableCell>
              <TableCell>Sessions Available</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myPackages.map((row, index) => (
              <TableRow key={row.packageDesc}>
                <TableCell>{row.packageDesc}</TableCell>
                <TableCell>{toDateValue(row.dateAdded)}</TableCell>
                <TableCell>{row.sessions - row.usage.length}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="massage-box">
        {classesFiltered.map((cla, index) => (
          <MyClassesCard key={index} cInfo={cla} onRemove={removeStudent} />
        ))}
      </div>
    </div>
  );
}

export default MyClasses;
