import React, { useState } from "react";
import * as Realm from "realm-web";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import AccountCircle from "@mui/icons-material/AccountCircle";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "64px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: "16px",
    backgroundColor: "#3f50b5",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "8px",
  },
  submit: {
    margin: "24px 0 16px",
  },
  emailField: {
    margin: "40px 0 0",
  },
}));

// These flags errorForm and errorReset are used to avoid
// the "race" condition by using the values set using useState()
let errorForm = false;
let errorReset = false;

function PasswordReset(props) {
  const classes = useStyles();

  // TODO: Save app id in an env file
  const app = new Realm.App({ id: "yapura-wellness-cemes" });

  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const tokenId = params.get("tokenId");

  const [clientInfo, setClientInfo] = useState({ password: "" });

  const [pwdValidation, setPwdValidation] = useState({
    error: false,
    errorMsg: "",
  });

  const [errReset, setErrReset] = useState({
    show: false,
    error: false,
    errorMsg: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    //This clears any previous alerts if user is changing inputs
    setErrReset({
      show: false,
      error: false,
      errorMsg: "",
    });
    setPwdValidation({
      error: false,
      errorMsg: "",
    });
    setClientInfo((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function checkValidation() {
    if (clientInfo.password.length < 8) {
      setPwdValidation({
        error: true,
        errorMsg: "Please use 8 or more characters",
      });
      errorForm = true;
    } else {
      errorForm = false;
    }
    // Removing mongoDB error message if client is re-trying password reset
    if (!errorForm) {
      setErrReset({
        show: false,
        error: false,
        errorMsg: "",
      });
    }
  }

  async function requestPasswordReset(userInfo) {
    console.log("token = ", token);
    console.log("tokenId = ", tokenId);
    try {
      // Requesting password reset
      const result = await app.emailPasswordAuth.resetPassword(
        token,
        tokenId,
        userInfo.password
      );
      errorReset = false;
      setErrReset((prevValue) => {
        return { ...prevValue, show: true };
      });
      return { auth: result, err: errorReset };
    } catch (err) {
      setErrReset({
        show: true,
        error: true,
        errorMsg: err.message.replace(/\([^()]*\)/g, ""),
      });
      errorReset = true;
      console.log("Failed to request password reset", err.message);
      return { auth: {}, err: errorReset };
    }
  }

  async function requestReset(userData) {
    checkValidation();
    console.log("error on form elements = " + errorForm);
    if (!errorForm) {
      requestPasswordReset(userData).then((returned) => {
        if (!returned.err) {
          console.log("request returned: ", returned.auth);
          console.log("request returned type: ", typeof returned.auth);
        } else {
          console.log(typeof returned);
          console.log("Error Requesting Password Reset Email!");
        }
      });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container justifyContent="center">
            <Grid>
              <Avatar className={classes.avatar}>
                <AccountCircle fontSize="large" />
              </Avatar>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid>
              <Typography component="h1" variant="h5">
                Create a new password
              </Typography>
            </Grid>
          </Grid>
          <TextField
            error={pwdValidation.error}
            helperText={pwdValidation.errorMsg}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            autoFocus
            value={clientInfo.password}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(event) => {
              requestReset(clientInfo);
              event.preventDefault();
            }}
          >
            Reset Password
          </Button>
          {errReset.error && errReset.show && (
            <Alert severity="error">{errReset.errorMsg}</Alert>
          )}
          {!errReset.error && errReset.show && (
            <Alert severity="success">
              Your password has been reset. Please sign in with your new
              password.
            </Alert>
          )}
        </form>
      </div>
    </Container>
  );
}

export default PasswordReset;
