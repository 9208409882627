import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from "@mui/icons-material/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  orderedItem: {
    marginTop: "4px",
    marginBottom: "4px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  orderedNumber: {
    marginTop: "4px",
    minWidth: "26px",
  },
  orderedText: {
    marginLeft: "0px",
  },
}));

function ServiceViewContentList(props) {
  const classes = useStyles();
  return (
    <List dense="true">
      {props.listContent.map((listItem, index) => (
        <ListItem className={classes.orderedItem} alignItems="flex-start">
          {props.paragraphType === "references" && (
            <ListItemIcon className={classes.orderedNumber}>
              {index + 1}.
            </ListItemIcon>
          )}
          {props.paragraphType === "bulletList" && (
            <ListItemIcon className={classes.orderedNumber}>
              <CircleIcon fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText className={classes.orderedText}>
            {listItem}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

export default ServiceViewContentList;
