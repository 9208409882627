const myApptList = [
  {
    key: 1,
    title: "Rejuvenate",
    date: "Mon Feb 5, 2021",
    time: "4:45 PM",
    frequency: "One Time",
  },
  {
    key: 2,
    title: "Rejuvenate",
    date: "Mon Feb 5, 2021",
    time: "4:45 PM",
    frequency: "One Time",
  },
  {
    key: 3,
    title: "Rejuvenate",
    date: "Mon Feb 5, 2021",
    time: "4:45 PM",
    frequency: "One Time",
  },
  {
    key: 4,
    title: "Rejuvenate",
    date: "Mon Feb 5, 2021",
    time: "4:45 PM",
    frequency: "One Time",
  },
];

export default myApptList;
