import React, { useState, useEffect, useRef } from "react";
import * as EmailValidator from "email-validator";
import { Wrapper } from "@googlemaps/react-wrapper";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import HelpIcon from "@mui/icons-material/Help";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  info: {
    margin: "8px 0 0",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "8px",
  },
  submit: {
    margin: "16px 0 0",
  },
  nameField: {
    margin: "16px 0 0",
  },
  contactContainer: {
    // paddingTop: { xs: "32px", sm: "32px", md: "32px", lg: "32px" },
    paddingTop: "24px",
    paddingBottom: "24px",
    background: "#fbe9e7",
    justifyContent: "center",
    alignItems: "center",
  },
  addressContainer: {
    alignItems: "center",
  },
  mapBox: {
    paddingLeft: 0,
    // paddingTop: "16px",
    // paddingBottom: "16px",
  },
  hoursContainer: {
    paddingTop: "8px",
    direction: "row",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  addressInfo: {
    display: "flex",
    paddingTop: "16px",
    paddingBottom: "16px",
    alignItems: "center",
  },
}));

// These flags errorForm and errorRegistration are used to avoid the "race" condition by using the values set using useState()
let errorForm = false;

function ContactUs(props) {
  const classes = useStyles();

  const [clientInfo, setClientInfo] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [emailValidation, setEmailValidation] = useState({
    error: false,
    errorMsg: "",
  });
  const [messageValidation, setMessageValidation] = useState({
    error: false,
    errorMsg: "",
  });
  const [errMessage, setErrMessage] = useState({
    show: false,
    error: false,
    errorMsg: "",
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setErrMessage({
      error: false,
      errorMsg: "",
    });
    if (name === "email") {
      setEmailValidation({
        error: false,
        errorMsg: "",
      });
    }
    if (name === "message") {
      setMessageValidation({
        error: false,
        errorMsg: "",
      });
    }
    setClientInfo((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function checkValidation() {
    if (clientInfo.email.length < 1) {
      setEmailValidation((prevValue) => {
        return {
          ...prevValue,
          error: true,
          errorMsg: "Email can't be blank",
        };
      });
      errorForm = true;
    } else {
      errorForm = false;
    }

    if (!EmailValidator.validate(clientInfo.email)) {
      setEmailValidation({
        error: true,
        errorMsg: "Email has to be of form name@domain.com",
      });
      errorForm = true;
    }
    if (clientInfo.message.length < 1) {
      setMessageValidation({
        error: true,
        errorMsg: "Message can't be blank",
      });
      errorForm = true;
    }

    // Removing send email error message if client trying to send message again
    if (!errorForm) {
      setErrMessage({
        error: false,
        errorMsg: "",
      });
    }
  }

  async function callMongoDBFunction() {
    try {
      const result = props.onCall("postWebMessage", clientInfo);
      console.log("function call result: ", JSON.stringify(result));
      setErrMessage((prevValue) => {
        return { ...prevValue, show: true };
      });
      return result;
    } catch (err) {
      setErrMessage({
        show: true,
        error: true,
        errorMsg: err.message.replace(/\([^()]*\)/g, ""),
      });
      console.log("Failed to call function", err.message);
    }
  }

  function sendMessage() {
    checkValidation();
    console.log("error on form elements = " + errorForm);
    if (!errorForm) {
      callMongoDBFunction().then((result) => {
        console.log("result from function call ", result);
      });
    }
  }

  function MyMapComponent() {
    // { center, zoom } = {
    //   center: google.maps.LatLngLiteral,
    //   zoom: number,
    // }
    //)
    const ref = useRef();
    const style = { height: "300px", width: "400px" };
    useEffect(() => {
      const map = new window.google.maps.Map(ref.current, {
        center: { lat: 35.876259, lng: -78.643114 },
        zoom: 11,
      });
      new window.google.maps.Marker({
        position: { lat: 35.876259, lng: -78.643114 },
        map,
        title: "Yapura Wellness",
      });
    });

    return <div ref={ref} style={style} id="map" />;
  }
  // const center = { lat: -34.397, lng: 150.644 };
  // const zoom = 4;
  //TODO

  return (
    <>
      <Grid container className={classes.contactContainer}>
        <Grid item md={6} className={classes.addressContainer}>
          <Box display="flex" justifyContent="flex-end" height="240px">
            <Box display="flex" justifyContent="center" minWidth="400px">
              <Typography component="div" className={classes.addressInfo}>
                <Grid alignItems="center">
                  <Box textAlign="center" fontSize="1.2rem">
                    <strong>Yapura Wellness</strong>
                  </Box>
                  <Box textAlign="center">
                    156 Mine Lake Ct, Suite 100, Raleigh, NC 27615
                  </Box>
                  <Box textAlign="center">
                    <strong>phone:</strong> (984) 900-1254 or (984) 900-1516
                  </Box>
                  <Box textAlign="center">
                    <strong>email:</strong> contact@yapurawellness.com
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    className={classes.hoursContainer}
                  >
                    <Grid item>
                      <Box textAlign="center">
                        <strong>hours:</strong>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box textAlign="right">
                        <Box display="block">Mon-Wed</Box>
                        <Box display="block">Thurs</Box>
                        <Box display="block">Fri</Box>
                        <Box display="block">Sat</Box>
                        <Box display="block">Sun</Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box textAlign="left">
                        <Box display="block">08:00 - 18:00</Box>
                        <Box display="block">Closed</Box>
                        <Box display="block">08:00 - 18:00</Box>
                        <Box display="block">09:00 - 17:00</Box>
                        <Box display="block">09:00 - 16:00</Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Typography>
            </Box>{" "}
          </Box>
        </Grid>{" "}
        <Grid item md={6}>
          <Box
            className={classes.mapBox}
            display="flex"
            justifyContent="flex-start"
            height="300px"
          >
            <Box
              height="300px"
              display="flex"
              width="400px"
              justifyContent="center"
            >
              <Wrapper apiKey={"AIzaSyAmNSl6tUY5CbO9nLWw4qpzMaa5EUe4Ko4"}>
                {/* <MyMapComponent center={center} zoom={zoom} /> */}
                <MyMapComponent />
              </Wrapper>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <form className={classes.form} noValidate>
            <Grid container justifyContent="center" alignItems="center">
              <Grid>
                <Avatar
                  sx={{
                    bgcolor: "#3f51b5",
                    m: 1,
                  }}
                >
                  <HelpIcon fontSize="large" />
                </Avatar>
              </Grid>
              <Grid>
                <Typography component="h1" variant="h5">
                  Contact Us
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <Alert severity="info" className={classes.info}>
                  Send us a message here if you have any questions or comments.
                </Alert>
              </Grid>
            </Grid>
            <TextField
              // error={nameValidation.error}
              // helperText={nameValidation.errorMsg}
              className={classes.nameField}
              variant="outlined"
              margin="normal"
              fullWidth
              name="name"
              label="Name"
              placeholder="Name"
              type="text"
              id="name"
              autoComplete="name"
              autoFocus="true"
              value={clientInfo.name}
              onChange={handleChange}
            />
            <TextField
              className={classes.nameField}
              error={emailValidation.error}
              helperText={emailValidation.errorMsg}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              placeholder="name@example.com"
              name="email"
              type="email"
              autoComplete="email"
              value={clientInfo.email}
              onChange={handleChange}
            />
            <TextField
              // error={nameValidation.error}
              // helperText={nameValidation.errorMsg}
              className={classes.nameField}
              variant="outlined"
              margin="normal"
              fullWidth
              name="subject"
              label="Subject"
              placeholder="Subject"
              type="text"
              id="subject"
              autoComplete="subject"
              value={clientInfo.subject}
              onChange={handleChange}
            />
            <TextField
              className={classes.nameField}
              error={messageValidation.error}
              helperText={messageValidation.errorMsg}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              multiline
              rows={4}
              name="message"
              label="Message"
              placeholder="your message ..."
              type="text"
              id="message"
              autoComplete="current-message"
              value={clientInfo.message}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              // style={{ backgroundColor: "#577c5c", color: "white" }}
              color="primary"
              className={classes.submit}
              onClick={(event) => {
                sendMessage();
                event.preventDefault();
              }}
            >
              Send Message
            </Button>
            {errMessage.error && errMessage.show && (
              <Alert severity="error">{errMessage.errorMsg}</Alert>
            )}
            {!errMessage.error && errMessage.show && (
              <Alert severity="success">
                Your message has been sent! We will get back to you ASAP.
              </Alert>
            )}
          </form>
        </div>
      </Container>
    </>
  );
}

export default ContactUs;
