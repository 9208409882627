import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from "@mui/icons-material/FiberManualRecord";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import PsychologyIcon from "@mui/icons-material/Psychology";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { orange, green, purple } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  orderedItem: {
    marginTop: "0px",
    marginBottom: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  orderedText: {
    marginLeft: "0px",
  },
  cardHorizontal: {
    height: "250px",
    display: "flex",
  },
  avatar: {
    // backgroundColor: "#d1c4e9", green-#81c784
    display: "flex",
    justifyContent: "center",
  },
}));

function ServiceViewThird(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <Container maxWidth="false">
      <Grid container>
        {props.content.map((service) => (
          <Grid item xs={4} sx={{ minWidth: 275, p: "8px" }}>
            <Card className={classes.cardHorizontal}>
              <CardContent sx={{ p: "16px 8px" }}>
                <div className={classes.avatar}>
                  {service.contentStyle === "mind" && (
                    <Avatar
                      sx={{ backgroundColor: purple[200] }}
                      variant="rounded"
                    >
                      <PsychologyIcon />
                    </Avatar>
                  )}
                  {service.contentStyle === "soul" && (
                    <Avatar
                      sx={{ backgroundColor: green[500] }}
                      variant="rounded"
                    >
                      <SentimentVerySatisfiedIcon />
                    </Avatar>
                  )}
                  {service.contentStyle === "body" && (
                    <Avatar
                      sx={{ backgroundColor: orange[200] }}
                      variant="rounded"
                    >
                      <DirectionsRunIcon />
                    </Avatar>
                  )}
                </div>
                <Typography align="center" variant="h6" component="div">
                  {service.subTitle}
                </Typography>
                <List dense="true">
                  {service.listContent.map((listItem) => (
                    <ListItem
                      className={classes.orderedItem}
                      sx={{ ml: "4px", pl: "4px" }}
                      alignItems="flex-start"
                    >
                      <ListItemIcon sx={{ minWidth: "24px", mt: "6px" }}>
                        <CircleIcon sx={{ fontSize: "16px" }} />
                      </ListItemIcon>
                      <ListItemText className={classes.orderedText}>
                        {listItem}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ServiceViewThird;
