const serviceListMeditation = {
  mainTitle: "Meditation",
  serviceView: [],
  list: [
    {
      key: 31,
      img: "images/Meditation_grounding.jpg",
      title: "Yoga Nidra",
      content:
        "Also called yogic sleep, this deep relaxation technique helps release tensions at the muscular, emotional, and mental levels. It is believed that a single hour of yoga nidra is as restful as four hours of conventional sleep. This class is given in-person on Saturday afternoons.",
      more: "Participants lie down on a yoga mat with cushions and bolsters to be comfortable as they follow the voice of the guide.  After the session, people usually report they feel rested, refreshed, calm and centered.  This class is limited to 3 students.",
      pricing: "Select a single session or a package.",
      appt: "false",
      benefits: "Grounding",
      avatarLetter: "M",
      pkgs: [
        {
          description: "1 Class = $15",
          value: {
            packageDesc: "1 x Meditation Class",
            packagePrice: 15,
            sessions: 1,
            type: "class",
            used: false,
          },
        },
        {
          description: "3 Classes = $40",
          value: {
            packageDesc: "3 x Meditation Classes",
            packagePrice: 40,
            sessions: 3,
            type: "class",
            used: false,
          },
        },
        {
          description: "10 Classes = $100",
          value: {
            packageDesc: "10 x Meditation Classes",
            packagePrice: 100,
            sessions: 10,
            type: "class",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
    },
    {
      key: 32,
      img: "images/Meditation.jpg",
      title: "Mindfulness Introduction Series",
      content:
        "Mindfulness is becoming aware of our internal and external experiences in the present moment with acceptance.  The benefits are stress reduction, better sleep, improved focus, stronger immune system, better relationships, and emotion management.",
      more: "This beginner level course is structured in 8 sessions of 1 hour. The aim of the introduction series is to give you an overview of mindfulness key principles and a strong basis to start your own practice at home.  The next series will start in January 2022.",
      pricing: "Select a single session or a package.",
      appt: "false",
      benefits: "Awareness",
      avatarLetter: "M",
      pkgs: [
        {
          description: "8 Class Series = $80",
          value: {
            packageDesc: "8 x Meditation Classes",
            packagePrice: 80,
            sessions: 8,
            type: "class",
            used: false,
          },
        },
        {
          description: "Gift Card",
          value: {
            packageDesc: "Gift Card",
            packagePrice: 0,
            used: false,
          },
        },
      ],
    },
  ],
};

export default serviceListMeditation;
