import React, { useState } from "react";
import * as Realm from "realm-web";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: "16px",
    },
  },
}));

//Flag to keep track if there was an error while resending confirmation email
let errorResend = false;

export default function RegisterInstructions(props) {
  console.log("the user email passed through is ", props.email);
  const classes = useStyles();

  const app = new Realm.App({ id: "yapura-wellness-cemes" });

  const [errResend, setErrResend] = useState({
    show: false,
    error: false,
    errorMsg: "",
  });

  async function resendEmail(email) {
    try {
      let response = await app.emailPasswordAuth.resendConfirmationEmail(email);
      errorResend = false;
      return errorResend;
    } catch (err) {
      setErrResend({
        show: true,
        error: true,
        errorMsg: err.message.replace(/\([^()]*\)/g, ""),
      });
      console.log(err);
      errorResend = true;
      return errorResend;
    }
  }

  function resendEmailConfirmation(email) {
    resendEmail(email).then((errorResend) => {
      !errorResend
        ? setErrResend({
            show: true,
            error: false,
            errorMsg: "Email resent successfully",
          })
        : console.log("Error resending email");
    });
  }

  // event.preventDefault();

  return (
    <div className={classes.root}>
      <Alert severity="info">
        <AlertTitle>Please check your email!</AlertTitle>
        You will receive an email from mongoDB with an email verification link
        that is valid for the next 30 minutes—{" "}
        <strong>this is needed to complete your registration!</strong> If you
        don't find this email, please also check your Junk folder. If you didn't
        get that email or more than 30 minutes have passed,{" "}
        <Link
          href="#"
          onClick={(event) => {
            resendEmailConfirmation(props.email);
          }}
        >
          <strong>click here to resend the email verification link.</strong>
        </Link>
      </Alert>
      {errResend.show && errResend.error && (
        <Alert severity="error">
          <AlertTitle>
            There was an error resending the email verfication link.{" "}
          </AlertTitle>
          Please try again later or{" "}
          <Link href="/contact-us">
            <strong>click here to contact us.</strong>
          </Link>
        </Alert>
      )}{" "}
      {errResend.show && !errResend.error && (
        <Alert severity="info">
          <AlertTitle>The email verification link has been resent!</AlertTitle>
          Please check your email again. If you still didn't receive the
          confirmation email please{" "}
          <Link href="/contact-us">
            <strong>click here to contact us.</strong>
          </Link>
        </Alert>
      )}
    </div>
  );
}
