import "./ClassCard.css";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { red } from "@mui/material/colors";
import RemoveIcon from "@mui/icons-material/Remove";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    marginTop: 8,
    marginLeft: 8,
  },
  titles: {
    fontSize: 16,
    minHeight: 30,
    paddingBottom: 0,
    marginBottom: 0,
  },
}));

function MyApptCard(props) {
  const classes = useStyles();

  function toDateValue(timeIn) {
    let dateVal = new Date(timeIn);
    return dateVal.toDateString();
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.titles}
        action={
          <Tooltip title="Remove" enterDelay={100}>
            <IconButton
              aria-label="settings"
              variant="soft"
              size="small"
              sx={{ bgcolor: red[100] }}
            >
              <RemoveIcon
                color="primary"
                onClick={(event) => {
                  props.onApptRemove(props.date);
                }}
              />
            </IconButton>
          </Tooltip>
        }
        // title={props.cInfo.date}
        subheader={`Service Requested:  ${props.title}`}
      />
      <CardActions disableSpacing>
        <div className="class-card-div">
          <p>
            {" "}
            <strong>Date Requested: </strong>
            {toDateValue(props.date)}
          </p>
          <p> a</p>
        </div>
        <div className="class-card-div">
          <p>
            {" "}
            <strong>Date Scheduled: </strong>
            {props.dateConf}
          </p>
          <p>
            {" "}
            <strong>Time Scheduled: </strong>
            {props.dateConf}
          </p>
        </div>
      </CardActions>
    </Card>
  );
}

export default MyApptCard;
