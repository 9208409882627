import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ServiceViewContentList from "./ServiceViewContentList";

// For the moment not using makeStyles.  Remove if not using anymore in next version
const useStyles = makeStyles((theme) => ({
  cardHorizontalHalf: {
    display: "flex",
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  backgroundColor: "#fff9c4",
}));

function ServiceViewHalf(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
  };

  return (
    <Container maxWidth="false">
      <Grid container>
        {props.content.map((service) => (
          <Grid item sm={6} sx={{ minWidth: 400, p: "8px" }}>
            {service.contentStyle === "text-image-left" && (
              <Card
                sx={{ minWidth: 400, backgroundColor: "#f5f5f5" }}
                variant="outlined"
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      height: "250px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* The first typography element is just to allow the title to take a "center" position */}
                      <Typography
                        align="center"
                        variant="h6"
                        component="div"
                        sx={{
                          fontSize: "20px",
                          mb: "20px",
                        }}
                      >
                        {" "}
                      </Typography>
                      <CardContent>
                        <Typography
                          align="center"
                          variant="h6"
                          component="div"
                          color="#424242"
                          sx={{
                            fontSize: "17px",
                          }}
                        >
                          {service.subTitle}
                        </Typography>
                      </CardContent>
                      <CardActions
                        disableSpacing
                        sx={{
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          align="center"
                          variant="h6"
                          color="text.secondary"
                          component="div"
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          {service.callToActionText}
                        </Typography>
                        <ExpandMore
                          expand={expanded}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="show more"
                          sx={{
                            ml: "12px",
                          }}
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </CardActions>
                    </Box>

                    <CardMedia
                      component="img"
                      sx={{ width: 200 }}
                      image={service.img}
                      alt="Yapura Wellnessoffers yoga, massage, meditation, and coaching"
                    />
                  </Box>

                  <Box>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent>
                        {service.paragraphs.map((paragraphItem) => (
                          <Typography paragraph>
                            {paragraphItem.paragraphType === "text" &&
                              paragraphItem.textParagraph}
                            {(paragraphItem.paragraphType === "references" ||
                              paragraphItem.paragraphType === "bulletList") && (
                              <ServiceViewContentList
                                listContent={paragraphItem.listContent}
                                paragraphType={paragraphItem.paragraphType}
                              />
                            )}
                          </Typography>
                        ))}
                      </CardContent>
                    </Collapse>
                  </Box>
                </Box>
              </Card>
            )}
            {service.contentStyle === "text-image-right" && (
              <Card
                sx={{ minWidth: 400, backgroundColor: "#f5f5f5" }}
                variant="outlined"
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      height: "250px",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: 200 }}
                      image={service.img}
                      alt="Yapura Wellnessoffers yoga, massage, meditation, and coaching"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* The first typography element is just to allow the title to take a "center" position */}
                      <Typography
                        align="center"
                        variant="h6"
                        component="div"
                        sx={{
                          fontSize: "20px",
                          mb: "20px",
                        }}
                      >
                        {" "}
                      </Typography>
                      <CardContent>
                        <Typography
                          align="center"
                          variant="h6"
                          component="div"
                          color="#424242"
                          sx={{
                            fontSize: "17px",
                          }}
                        >
                          {service.subTitle}
                        </Typography>
                      </CardContent>
                      <CardActions
                        disableSpacing
                        sx={{
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          align="center"
                          variant="h6"
                          color="text.secondary"
                          component="div"
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          {service.callToActionText}
                        </Typography>
                        <ExpandMore
                          expand={expanded2}
                          onClick={handleExpandClick2}
                          aria-expanded={expanded2}
                          aria-label="show more"
                          sx={{
                            ml: "12px",
                          }}
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </CardActions>
                    </Box>
                  </Box>

                  <Box>
                    <Collapse in={expanded2} timeout="auto" unmountOnExit>
                      <CardContent>
                        {service.paragraphs.map((paragraphItem) => (
                          <Typography paragraph>
                            {paragraphItem.paragraphType === "text" &&
                              paragraphItem.textParagraph}
                            {(paragraphItem.paragraphType === "references" ||
                              paragraphItem.paragraphType === "bulletList") && (
                              <ServiceViewContentList
                                listContent={paragraphItem.listContent}
                                paragraphType={paragraphItem.paragraphType}
                              />
                            )}
                          </Typography>
                        ))}
                      </CardContent>
                    </Collapse>
                  </Box>
                </Box>
              </Card>
            )}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ServiceViewHalf;
